import { useEffect } from "react";
import { Link } from "react-router-dom";
import healthInsur from 'src/assets/images/sme_insurance.svg';
import corpBuilding from 'src/assets/images/corporate_building_v2.jpg';

import marketChart from 'src/assets/images/market_chart.jpg';


export default function CorpProdsProd(){
    document.title = 'Corporate Insurance products - FIS Ltd';

    useEffect(() => {    
        window.scrollTo(0, 0);
    }, []);


    return (
      <section>
        <div className="content-header">
          <div className="container">
            <div className="header-inner">
              <h3>
                <span>Corporate</span> Insurance products
              </h3>
              <img src={healthInsur} alt="" />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container">
            <div className="boddy-inner">
              <div className="left">
                <img
                  className="w-100 mb-2"
                  src={corpBuilding}
                  alt="Corporate insurance products"
                />
                <h3 className="main-color">
                  List of Corporate Insurance Products
                </h3>
                <ul className="list-right-pointers mb-4">
                  <li>
                    <Link to="/products/corpprods/fire">Fire</Link>
                  </li>
                  <li>
                    <Link to="/products/corpprods/burglary">Bulgary</Link>
                  </li>
                  <li>
                    <Link to="/products/corpprods/allrisks">All risks</Link>
                  </li>
                  <li>
                    <Link to="/products/corpprods/money">Money</Link>
                  </li>
                  <li>
                    <Link to="/products/corpprods/fidelityguar">
                      Fidelity Guarantee
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/corpprods/goodstransit">
                      Goods in Transit
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/corpprods/commercmotor">
                      Commercial Motor
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/corpprods/groupersonacc">
                      Group Personal Accident
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/corpprods/marine">Marine</Link>
                  </li>
                  <li>
                    <Link to="/products/corpprods/contractors">
                      Contractors All Risks
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/corpprods/injurybenefit">
                      Work Injury Benefit Act
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/corpprods/glass">Glass</Link>
                  </li>
                  <li>
                    <Link to="/products/corpprods/publicliab">
                      Public Liability
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/corpprods/professindemn">
                      Professional Indemnity
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/corpprods/electrequip">
                      Electronic Equipment
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/corpprods/conseqloss">
                      Consequential Loss
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="right">
                {/* <!--Insurance Market Stability--> */}
                <div className="profile-card card mb-4">
                  <img
                    src={marketChart}
                    className="card-img-top"
                    alt="Insurance Market Stability"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Insurance Market Stability</h5>
                    <div className="card-text">
                      <ul className="list-right-pointers">
                        <li>
                          The financial and management strength, attitude
                          towards claims and efficiency of the insurers is a
                          matter we take very seriously.
                        </li>
                        <li>
                          We conduct a review of all the insurance companies
                          annually and rate them, according to the Following
                          categories; Outstanding, A, B, C, D.
                        </li>
                        <li>
                          We ordinarily place our business with the insurance
                          companies in the outstanding, A, B & C categories.
                          Should a client prefer to have a placement in an
                          insurance company Rated otherwise we insist on a
                          letter of instruction.
                        </li>
                        <li>
                          This rating of insurance companies is not static and
                          we review it regularly on an on-going basis.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <!--Insurance Market Stability--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}