import { useEffect } from "react";

import OurServiceComp from "src/components/OurServiceComp";
import QuoteFormComp from "src/components/QuoteFormComp";


import healthInsur from 'src/assets/images/package.svg';


export default function DomesticPackageProd(){
    document.title = 'Domestic Package - FIS Ltd';

    useEffect(() => {    
        window.scrollTo(0, 0);
    }, []);

    return (
      <section>
        <div className="content-header">
          <div className="container">
            <div className="header-inner">
              <h3>Domestic Package</h3>
              <img src={healthInsur} alt="" />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container">
            <div className="boddy-inner">
              <div className="left">
                <p>
                  This insurance package covers accidental loss or damage to
                  residential homes and or contents of a dwelling house
                  including your valuable items normally carried or worn. It
                  incorporates your personal liability and that of your house
                  hold members. The policy also covers the cost and expenses
                  that you incur in the event of death of or injury or disease
                  to your domestic servant arising out of and in the course of
                  his or her employment as defined in the Work Injury Benefits
                  Act.
                </p>
              </div>
              <div className="right">
                {/* <!--Request quote--> */}
                <QuoteFormComp productName={"Domestic Package Insurance"} />
                {/* <!--Request quote--> */}
                {/* <!--Our services--> */}
                <OurServiceComp />
                {/* <!--Our services--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}