import { useEffect } from "react";

import OurServiceComp from "src/components/OurServiceComp";
import QuoteFormComp from "src/components/QuoteFormComp";


import healthInsur from 'src/assets/images/money_transport.svg';


export default function Money(){
    document.title = 'Money - FIS Ltd';

    useEffect(() => {    
        window.scrollTo(0, 0);
    }, []);

    return (
      <section>
        <div className="content-header">
          <div className="container">
            <div className="header-inner">
              <h3>Money</h3>
              <img src={healthInsur} alt="" />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container">
            <div className="boddy-inner">
              <div className="left">
                <p>
                  This class of insurance provides cover for loss of money
                  whilst in transit, between premises, bank post office by
                  robbery theft or accident. Held in locked safe/or strong room
                  in the insured premises by burglary or house breaking, damage
                  to safe or strong room following theft or attempted theft.
                  Money includes cash, bank currency notes bank, postal orders
                  credit card vouchers and open cheques. It is issued on
                  declaration basis.
                </p>
              </div>
              <div className="right">
                {/* <!--Request quote--> */}
                <QuoteFormComp productName={"Money Insurance"} />
                {/* <!--Request quote--> */}
                {/* <!--Our services--> */}
                <OurServiceComp />
                {/* <!--Our services--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}