import { NetReturn, SenderData } from "src/models/netreturn";
import AppDeclarations from "src/utils/declarations";


async function requestGet(methodName: string): Promise<NetReturn> {
    const url = `${AppDeclarations.apiRootTest}${methodName}`;
    const response  = await window.fetch(url, {
        method: 'GET',
    });

    if(response.ok){
        const json = await response.json();
        const commits = json as NetReturn;
        return commits;
    }else{
        return {
            bReturn: false,
            msgTitle: 'Network error',
            msgBody: 'There is a network error on this device. Check this issue and try again.',
            typeReturn: 0,
        }
    }
}


async function requestPost(methodName: string, data: SenderData): Promise<NetReturn> {
    const url = `${AppDeclarations.apiRootTest}${methodName}`;
    const headers = {'Content-Type': 'application/json',}

    const response  = await window.fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    });

    if(response.ok){
        const json = await response.json();
        const commits = json as NetReturn;
        return commits;
    }else{
        return {
            bReturn: false,
            msgTitle: 'Network error',
            msgBody: 'There is a network error on this device. Check this issue and try again.',
            typeReturn: 0,
        }
    }
}

const NetServices = {
    requestGet,
    requestPost,
}

export default NetServices;