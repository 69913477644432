import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from './pages/MainLayout';

import HomeApp from './pages/home/HomeApp';
import ContacUs from './pages/home/ContactApp';


import AboutCompany from './pages/about/CompanyAbout';
import AboutBoard from './pages/about/BoardAbout';
import AboutStaff from './pages/about/StaffAbout';


import IndivProdBonds from './pages/products/indivprods/BondsProd';
import IndivProdDomestic from './pages/products/indivprods/DomesticPackageProd';
import IndivProdGoodsTr from './pages/products/indivprods/IndGoodsInTransit';
import IndivProdPersAcc from './pages/products/indivprods/PersonalAcc';
import IndivProdMedInsur from './pages/products/indivprods/MedicInsurance';
import IndivProdMotor from './pages/products/indivprods/Motor';
import IndivProdProfInde from './pages/products/indivprods/ProfIndemn';
import IndivProdHealth from './pages/products/indivprods/Health';


import AllRisksProd from './pages/products/corpprods/AllRisksProd';
import BulgaryProd from './pages/products/corpprods/BulgaryProd';
import CommercialMotorProd from './pages/products/corpprods/CommercialMotorProd';
import ConseqLoss from './pages/products/corpprods/ConseqLoss';
import ContractorsProd from './pages/products/corpprods/ContractorsProd';
import ElectrEquipment from './pages/products/corpprods/ElectrEquipment';
import FidGuar from './pages/products/corpprods/FidGuar';
import Fire from './pages/products/corpprods/Fire';
import Glass from './pages/products/corpprods/Glass';
import GoodsTransit from './pages/products/corpprods/GoodsTransit';
import GrpPersAcc from './pages/products/corpprods/GrpPersAcc';
import InjuryBenefit from './pages/products/corpprods/InjuryBenefit';
import Marine from './pages/products/corpprods/Marine';
import Money from './pages/products/corpprods/Money';
import ProfessionelIndemnity from './pages/products/corpprods/ProfessionelIndemnity';
import PublicLiab from './pages/products/corpprods/PublicLiab';


import SmeProds from './pages/products/SmeProds';
import IndivProds from './pages/products/IndivProds';
import CorpProdsProd from './pages/products/CorpProdsProd';

import LegalTerms from './pages/legal/TermsLegal';
import LegalPrivacy from './pages/legal/PrivacyLegal';
import LegalCookies from './pages/legal/CookiesLegal';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<MainLayout />}>
          <Route index element={<HomeApp />} />
          <Route path="contactus" element={<ContacUs />} />

          <Route path="about/company" element={<AboutCompany />} />
          <Route path="about/board" element={<AboutBoard />} />
          <Route path="about/staff" element={<AboutStaff />} />
          
          <Route path="products/smeprods" element={<SmeProds />} />
          <Route path="products/indivprods" element={<IndivProds />} />
          <Route path="products/corpprods" element={<CorpProdsProd />} />

          <Route path="products/indivprods/health" element={<IndivProdHealth />} />
          <Route path="products/indivprods/motor" element={<IndivProdMotor />} />
          <Route path="products/indivprods/domestic" element={<IndivProdDomestic />} />
          <Route path="products/indivprods/persacc" element={<IndivProdPersAcc />} />
          <Route path="products/indivprods/medinsur" element={<IndivProdMedInsur />} />
          <Route path="products/indivprods/profindemn" element={<IndivProdProfInde />} />
          <Route path="products/indivprods/goodstransit" element={<IndivProdGoodsTr />} />
          <Route path="products/indivprods/bonds" element={<IndivProdBonds />} />

          
          <Route path="products/corpprods/fire" element={<Fire />} />
          <Route path="products/corpprods/burglary" element={<BulgaryProd />} />
          <Route path="products/corpprods/allrisks" element={<AllRisksProd />} />
          <Route path="products/corpprods/money" element={<Money />} />
          <Route path="products/corpprods/fidelityguar" element={<FidGuar />} />
          <Route path="products/corpprods/goodstransit" element={<GoodsTransit />} />
          <Route path="products/corpprods/commercmotor" element={<CommercialMotorProd />} />
          <Route path="products/corpprods/groupersonacc" element={<GrpPersAcc />} />
          <Route path="products/corpprods/marine" element={<Marine />} />
          <Route path="products/corpprods/contractors" element={<ContractorsProd />} />
          <Route path="products/corpprods/injurybenefit" element={<InjuryBenefit />} />
          <Route path="products/corpprods/glass" element={<Glass />} />
          <Route path="products/corpprods/publicliab" element={<PublicLiab />} />
          <Route path="products/corpprods/professindemn" element={<ProfessionelIndemnity />} />
          <Route path="products/corpprods/electrequip" element={<ElectrEquipment />} />
          <Route path="products/corpprods/conseqloss" element={<ConseqLoss />} />

          <Route path="legal/cookies" element={<LegalCookies />} />
          <Route path="legal/privacy" element={<LegalPrivacy />} />
          <Route path="legal/terms" element={<LegalTerms />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
