import { useEffect } from "react";

import OurServiceComp from "src/components/OurServiceComp";
import QuoteFormComp from "src/components/QuoteFormComp";


import healthInsur from 'src/assets/images/goods_transit.svg';

export default function IndGoodsInTransit(){
    document.title = 'Goods in Transit / Individual - FIS Ltd';

    useEffect(() => {    
        window.scrollTo(0, 0);
    }, []);

    return (
      <section>
        <div className="content-header">
          <div className="container">
            <div className="header-inner">
              <h3>Goods in Transit / Individual</h3>
              <img src={healthInsur} alt="" />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container">
            <div className="boddy-inner">
              <div className="left">
                <p>
                  The Goods in Transit policy offers protection against loss,
                  destruction, or damage to goods whilst they are in transit -
                  from the time of leaving the insured's premises to final
                  delivery.
                </p>
                <p>
                  The policy also protects the insured from liability during
                  loading and unloading of the goods, and temporary housing in
                  the course of transit.
                </p>
                <p>
                  The cover is available for individuals or companies
                  transporting goods.
                </p>
              </div>
              <div className="right">
                {/* <!--Request quote--> */}
                <QuoteFormComp productName={"Goods in Transit / Individual"} />
                {/* <!--Request quote--> */}
                {/* <!--Our services--> */}
                <OurServiceComp />
                {/* <!--Our services--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}