import { useEffect } from 'react';
import leaglCookies from 'src/assets/images/cookie_icon.svg';
import cookiesPrivacy from 'src/assets/images/cookie_privacy_data.svg';

export default function CookiesLegal(){    
    document.title = 'Cookies policy - FIS Ltd';

    useEffect(() => {    
        window.scrollTo(0, 0);
    }, []);


    return (
      <section>
        <div className="data-title">
          <div className="container">
            <div className="data-title-content terms">
              <h4>Cookies</h4>
              <img src={leaglCookies} alt="Cookies Policy" />
            </div>
          </div>
        </div>
        <div className="data-content">
          <div className="container">
            <div className="data-content-content terms">
              <div className="prod-explain">
                <div className="data-start">
                  <p className="font-p-large text-faded mb-4">
                    This Cookie Notice describes the different types of cookies
                    and similar technologies that may be applied on FIS.rw and,
                    where relevant, on other Falcon Insurance Services that link
                    to this Cookie Notice (the “Sites”).
                  </p>
                </div>
                <div className="data-image box-flex center">
                  <img src={cookiesPrivacy} alt="" />
                </div>
              </div>
              <div className="prod-details cookies">
                <div className="row">
                  <article className="col-12 col-lg-8">
                    <h3>1. What is a cookie?</h3>
                    <p>
                      Cookies are small text files that a website transfers to
                      your hard drive to store and sometimes collect information
                      about your usage of websites, such as time spent on the
                      Sites, pages visited, language preferences, and other
                      traffic data. We use the information for security
                      purposes, to facilitate navigation, to display information
                      more effectively, to personalize your experience while
                      using the Sites and to recognize your computer in order to
                      assist your use of the Sites. We also gather statistical
                      information about use of the Sites in order to improve
                      their design and functionality, understand how they are
                      used and assist us with resolving questions regarding
                      them.
                    </p>
                    <p>There are different types of cookies, for example:</p>
                    <ul>
                      <li>
                        Cookies served directly by Falcon Insurance Services
                        ('first party cookies') and cookies served on our
                        behalf, for example by advertisers and data analytics
                        companies ('third party cookies')
                      </li>
                      <li>
                        Cookies which endure for different periods of time,
                        including those that only last only as long as your
                        browser is open (referred to as 'session cookies') These
                        are deleted automatically once you close your browser.
                        Other cookies are 'permanent cookies', meaning that they
                        survive after your browser is closed. For example, they
                        recognize your device when you open your browser and
                        browse the internet again.
                      </li>
                    </ul>
                    <h3>2. What cookies do we use?</h3>
                    <p>
                      Below we list the different types of cookies and similar
                      technologies that may be used on the Sites. Where we use
                      third party cookies, we provide a link to the third
                      party's cookie policy.
                    </p>
                    <p>
                      To the extent any Personal Information (which is
                      information that identifies you as an individual or
                      relates to an identifiable individual) is collected
                      through cookies, our
                      <a href="/legal/privacy/">Privacy Notice</a> applies and
                      supplements this Cookie Notice.
                    </p>
                    <p>
                      <strong>Required Cookies.</strong>
                      Required cookies enable you to navigate the Sites and to
                      use their services and features. Without these necessary
                      cookies and similar technologies, the Sites will not
                      perform as smoothly for you as we would like them to and
                      we may not be able to provide the Sites or certain
                      services or features.
                    </p>
                    <p>
                      <strong>Functional Cookies.</strong>
                      Functional cookies collect information about your use of
                      the Sites, and enable us to improve the way they work. For
                      example, functional cookies show us which are the most
                      frequently visited pages on the Sites, help us record any
                      difficulties you have with the Sites, and show us whether
                      our promotional campaigns are effective. This allows us to
                      see the overall patterns of usage on the Sites. We use the
                      information to analyse traffic on the Sites.
                    </p>
                    <p>
                      <strong>Advertising Cookies.</strong>
                      Advertising cookies are set to display targeted promotions
                      or advertisements based upon your interests on the Sites
                      or to manage our advertising. These cookies collect
                      information about your activities on these and other sites
                      to provide you targeted advertising. You can find
                      information about advertising cookies, including how to
                      see what cookies have been set on your device and how to
                      manage and delete them
                      <a
                        rel="noopener noreferrer"
                        href="http://www.youronlinechoices.com/"
                        target="_blank"
                      >
                        here
                      </a>
                      .
                    </p>
                    <h3> 3. How do I manage cookies?</h3>
                    <p>
                      You have the right to choose whether or not to accept
                      cookies. You can control the way in which cookies are used
                      by altering your browser settings. You may refuse to
                      accept cookies by activating the setting on your browser
                      that allows you to reject cookies. Information about the
                      procedure to follow in order to enable or disable cookies
                      can be found on your Internet browser provider's website
                      via your help screen. You can change your cookie settings
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="http://www.youronlinechoices.com/"
                      >
                        here
                      </a>
                      .
                    </p>
                    <p>
                      However, if you choose not to accept cookies, this may
                      affect the functioning of our Sites.
                    </p>
                    <p>
                      If you are using a mobile device, you can limit
                      interest-based advertising on your device by adjusting the
                      settings provided by your device manufacturer, such as "
                      <a
                        rel="noreferrer"
                        href="https://support.apple.com/en-lamr/HT202074"
                        target="_blank"
                      >
                        Limit Ad Tracking
                      </a>
                      " for iOS or "
                      <a
                        rel="noreferrer"
                        href="https://www.google.com/intl/en/policies/technologies/ads/"
                        target="_blank"
                      >
                        Opt-out of interest-based ads
                      </a>
                      " for Android.
                    </p>
                    <p>
                      To find out more about cookies, including how to see what
                      cookies have been set and how to manage and delete them,
                      visit
                      <a
                        rel="noreferrer"
                        href="http://www.aboutcookies.org/"
                        target="_blank"
                      >
                        www.aboutcookies.org
                      </a>
                      , www.allaboutcookies.org, or{" "}
                      <a
                        rel="noreferrer"
                        href="http://www.youronlinechoices.eu/"
                        target="_blank"
                      >
                        www.youronlinechoices.eu
                      </a>
                      .
                    </p>
                    <h3>4. Changes to our Cookie Notice</h3>
                    <p>
                      We may change this Cookie Notice from time to time. Please
                      take a look at the "<em>Last Revised</em>" legend at the
                      top of this page to see when this Cookie Notice was last
                      revised. If we make significant changes to this Cookie
                      Notice, we will notify you of changes when you next visit
                      any of our Sites.
                    </p>
                    <h3>5. Contacts and comments</h3>
                    <p>
                      If you have any questions or comments regarding this
                      Cookie Notice, please contact us by email at{" "}
                      <a href="mailto:info@fis.rw">info@fis.rw</a>, or write to
                      the Rwanda Privacy Office at: (i) Falcon Insurance
                      Building Ltd, 3rd Floor Tele 10 Building opp. RDB
                      Gishushu, PO Box: 2523 Kigali, Rwanda.
                    </p>
                  </article>
                  <aside className="col-12 col-lg-4"></aside>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}