import { useEffect, useState } from "react";


import contactUsIcon from 'src/assets/images/contact_us.svg';
import companyBuilding from 'src/assets/images/company_building.jpg';
import FormGroupInputLabel from "src/components/FormGroupInputLabel";
import { ProdeQteInter } from "src/models/netreturn";
import { generalFx } from "src/utils/general_fxs";
import NetServices from "src/utils/netservices";

const emptyRecord:ProdeQteInter = {
    ProductRequestInfoId: 0,
    FirstName: '',
    LastName: '',
    FullAddress: '',
    PhoneNumber: '',
    EmailAddress: '',
    ProductName: 'Healt Isnurance',
    Message: '',
    CanReceiveMonthlyEmail: false,
    DateRequest: new Date(),
    DateReceived: new Date(),
    DateToBeContacted: new Date(),
    SecureCode: '',
};

export default function ContactApp(){
    const [formData, setFormData] = useState<ProdeQteInter>(emptyRecord);
    const [captchaValue, setCaptchaValue] = useState<string>('');

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccessSent, setIsSuccessSent] = useState(false);
    const [isErrorFromServer, setIsErrorFromServer] = useState(false);

    const isValidForm = () => {
        return formData.FirstName && formData.FirstName.trim() !== '' &&
        formData.LastName && formData.LastName.trim() !== '' &&
        formData.FullAddress && formData.FullAddress.trim() !== '' &&
        formData.PhoneNumber && formData.PhoneNumber.trim() !== '' && formData.PhoneNumber.length === 12 && 
        formData.SecureCode && formData.SecureCode.trim() !== '' && formData.SecureCode.length === 5 && 
        formData.EmailAddress && formData.EmailAddress.trim() !== '' && generalFx.isEmailValid(formData.EmailAddress) &&
        formData.Message && formData.Message.trim() !== '';
    }
    const checkCaptchaValid = () => {
        return formData.SecureCode === captchaValue;
    }
    const sendContactUsDataa = async() =>  {        
        setIsSubmitting(true);
        setIsErrorFromServer(false);
        setIsSuccessSent(false);

        const oFinalRecord = {
            ...formData, 
            ProductName: '-',
            DateRequest: generalFx.dateToUtcStr(formData.DateRequest),
            DateReceived: generalFx.dateToUtcStr(formData.DateReceived),
            DateToBeContacted: generalFx.dateToUtcStr(formData.DateToBeContacted),
            SecureCode: captchaValue,
        };

        const oData = {
            'productRequestInfo': oFinalRecord,
        };

        const retData = await NetServices.requestPost('web/sendrequestproduct', oData);

        if(!retData.bReturn){
            generalFx.showAlert(retData.msgTitle, retData.msgBody);
            setIsSubmitting(false);
            setIsErrorFromServer(true);
            setIsSuccessSent(false);
            return;
        }

        document.location = '/'
    }

    useEffect(() => {    
        setCaptchaValue(generalFx.randomCharsFormInts(5));
    }, []);

    
  document.title = 'Contact Us - FIS Ltd';

  useEffect(() => {    
      window.scrollTo(0, 0);
  }, []);

    return (
        <section>
        <div className="content-header">
        <div className="container">
            <div className="header-inner">
                <h3>Contact Us</h3>
                <img src={contactUsIcon} alt="" />
            </div>
        </div>
    </div>
    <div className="content-body p-2">
        <div className="container">
            <div className="mx-3 my-3">
                <h3>Kindly reach out for more insights</h3>
                <h6 className="font-weight-normal text-secondary">
                    <span>
                        Already have an account? <a href="https://250insurances.fis.rw" target="_blank" rel="noreferrer">Customer's portal</a>
                    </span>
                </h6>
            </div>
            <div className="row">
                <div className="col-md-7">
                    <div className="form-container">

                        <div className="w-card">
                            <h1 className="w-card-header">Send us an email</h1>
                            <div className="w-card-block">
                                <form name="frmContact" autoComplete="disabled">
                                    <div className="row">
                                        <FormGroupInputLabel
                                            type="text"
                                            componentLabel="First name"
                                            isTextArea={false}
                                            componentId="FirstName"
                                            placeholder="first name"
                                            value={formData.FirstName}
                                            key="FirstName"
                                            hasError={!formData.FirstName || formData.FirstName.trim() === ""}
                                            onChangeArea={(e) => {
                                                setFormData({
                                                ...formData,
                                                FirstName: e.target.value,
                                                });
                                            }}
                                            maxLength={15}
                                            parentClass="col-md-6"
                                        />
                                        <FormGroupInputLabel
                                            type="text"
                                            componentLabel="Last name"
                                            isTextArea={false}
                                            componentId="LastName"
                                            placeholder="last name"
                                            value={formData.LastName}
                                            key="LastName"
                                            hasError={!formData.LastName || formData.LastName.trim() === ""}
                                            onChangeArea={(e) => {
                                                setFormData({
                                                ...formData,
                                                LastName: e.target.value,
                                                });
                                            }}
                                            maxLength={15}
                                            parentClass="col-md-6"
                                        />
                                    </div>
                                    <div className="row">
                                        <FormGroupInputLabel
                                            type="text"
                                            componentLabel="Full address"
                                            isTextArea={true}
                                            rows={2}
                                            componentId="FullAddress"
                                            placeholder="full address"
                                            value={formData.FullAddress}
                                            key="FullAddress"
                                            hasError={!formData.FullAddress || formData.FullAddress.trim() === ""}
                                            onChangeArea={(e) => {
                                                setFormData({
                                                ...formData,
                                                FullAddress: e.target.value,
                                                });
                                            }}
                                            maxLength={100}
                                            parentClass="col-md-12"
                                        />
                                    </div>
                                    <div className="row">
                                        <FormGroupInputLabel
                                            type="text"
                                            componentLabel="Phone number"
                                            isTextArea={false}
                                            componentId="PhoneNumber"
                                            placeholder="phone number (250_________)"
                                            value={formData.PhoneNumber}
                                            key="PhoneNumber"
                                            hasError={!formData.PhoneNumber || formData.PhoneNumber.trim() === ""}
                                            onChange={(e) => {
                                                setFormData({
                                                ...formData,
                                                PhoneNumber: e.target.value,
                                                });
                                            }}
                                            maxLength={12}
                                            parentClass="col-md-6"
                                            isNumericFld={true}
                                        />
                                        <FormGroupInputLabel
                                            type="email"
                                            componentLabel="Email address"
                                            isTextArea={false}
                                            componentId="EmailAddress"
                                            placeholder="email address"
                                            value={formData.EmailAddress}
                                            key="EmailAddress"
                                            hasError={!formData.EmailAddress || formData.EmailAddress.trim() === "" || !generalFx.isEmailValid(formData.EmailAddress)}
                                            onChange={(e) => {
                                                setFormData({
                                                ...formData,
                                                EmailAddress: e.target.value,
                                                });
                                            }}
                                            maxLength={50}
                                            parentClass="col-md-6"
                                            isNumericFld={false}
                                        />                                        
                                    </div>
                                    <div className="row">
                                        <FormGroupInputLabel
                                            type="text"
                                            componentLabel="Message"
                                            isTextArea={true}
                                            rows={8}
                                            componentId="Message"
                                            placeholder="type your message here"
                                            value={formData.Message}
                                            key="Message"
                                            hasError={!formData.Message || formData.Message.trim() === ""}
                                            onChangeArea={(e) => {
                                                setFormData({
                                                ...formData,
                                                Message: e.target.value,
                                                });
                                            }}
                                            maxLength={2500}
                                            parentClass="col-md-12"
                                            isNumericFld={false}
                                        />
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <label className="form-check-label form-text">
                                            <input type="checkbox"
                                                   id="CanReceiveMonthlyEmail"
                                                   name="CanReceiveMonthlyEmail"
                                                   className="form-check-input"
                                                   checked={formData.CanReceiveMonthlyEmail} 
                                                   onChange={(_) => {
                                                    setFormData({
                                                    ...formData,
                                                    CanReceiveMonthlyEmail: !formData.CanReceiveMonthlyEmail,
                                                    });
                                                }}/>
                                            <div>Sign up for our monthly newsletter to receive tips and promotions. You can unsubscribe at any time.</div>
                                        </label>
                                    </div>
                                    <div className="recaptcha-box">
                                        <div className="cover">
                                            <input type="text"
                                                   id="SecureCode"
                                                   name="SecureCode"
                                                   value={formData.SecureCode}
                                                   autoComplete="off"
                                                   maxLength={5}
                                                   minLength={5}
                                                   className="number"
                                                   onChange={(e) => {
                                                    setFormData({
                                                    ...formData,
                                                    SecureCode: e.target.value,
                                                    });
                                                }}/>
                                            <div className="cap-photo">
                                                {captchaValue}
                                                <div className="hide"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <button type="button"
                                                className="btn btn-primary btn-block"
                                                disabled={!isValidForm() || isSubmitting || !checkCaptchaValid()}
                                                onClick={sendContactUsDataa}>
                                            {!isSubmitting ? <span>Send</span>: null}
                                            {isSubmitting? <span ng-if="isSubmitting">Sending contact request email</span>: null}
                                            {isSuccessSent && !isSubmitting ? <span>Email sent with success!</span>: null}
                                            {isSubmitting ? <>&nbsp;&nbsp;<i className="fa fa-spinner fa-spin"></i></>: null}
                                        </button>
                                        {isErrorFromServer ? <span className="formError">Error from server. Please check your connection</span>: null}
                                        <div className="text-center mt-1">
                                            <small className="text-secondary">
                                                By submitting this form, I agree to FIS's&nbsp;
                                                <a href="/legal/terms" target="_blank"
                                                   rel="noopener noreferrer">Terms of Use</a>&nbsp;and&nbsp;<a href="/legal/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                                            </small>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 d-none d-lg-block">
                    <div className="card mb-4">
                        <img src={companyBuilding} className="card-img-top" alt="Company Building" />
                        <div className="card-body">
                            <h5 className="card-title">Our Address</h5>
                            <div className="card-text mb-2">
                                <strong className="mb-1">Falcon Insurance Services Ltd</strong><br/>
                                3rd Floor Tele 10 Building <br/>
                                Opposite RDB Gishushu <br/>
                                <strong>PO Box:</strong> 2523 Kigali, Rwanda <br/>
                                <strong>Telephone:</strong> +250 788 301 033 <br/>
                                <strong>Email:</strong> info@fis.rw
                            </div>
                            <a href="http://250insurances.fis.rw" className="btn btn-primary" target="_blank" rel="noreferrer">
                                Go to Customer's portal
                            </a>
                        </div>
                    </div>

                    <div id="map"></div>
                </div>
            </div>
        </div>
    </div>
</section>
    );
}