import { useEffect } from "react";
import { Link } from "react-router-dom";
import healthInsur from 'src/assets/images/sme_insurance.svg';
import needBusiness from 'src/assets/images/need_business.jpg';
import marketChart from 'src/assets/images/market_chart.jpg';

export default function SmeProds(){
    document.title = 'SME Insurance - FIS Ltd';

    useEffect(() => {    
        window.scrollTo(0, 0);
    }, []);

    return (
      <section>
        <div className="content-header">
          <div className="container">
            <div className="header-inner">
              <h3>
                <span>SME</span> Insurance
              </h3>
              <img src={healthInsur} alt="" />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container">
            <div className="boddy-inner">
              <div className="left">
                <h3>SME Insurance Products</h3>
                <p>
                  Many large companies have entire teams of attorneys dedicated
                  to reviewing and sourcing insurance coverage - and often
                  developing their own. Small and medium-sized enterprises
                  (SMEs) often lack those teams, but still need access to SME
                  insurance products to protect themselves from liability and
                  property perils.
                </p>
                <p>
                  But what insurance solutions are relevant to your business?
                </p>
                <h3>What types of insurance products do SMEs need?</h3>
                <p>
                  There is a virtually unlimited number of different coverage
                  options and combinations. If you want to talk about issues
                  specific to your business or industry,{" "}
                  <Link to="/contactus">let us know</Link>! However, there are
                  some core coverages that every small and medium-sized business
                  needs, no matter what industry they are in.
                </p>
                <h3>Commercial Liability</h3>
                <p>
                  Commercial general liability covers your business from real or
                  alleged claims of bodily injury or property damage caused by
                  your business operations.
                </p>
                <p>
                  While some businesses have significantly more liability
                  exposure than others, we think any company that generates
                  revenue needs this coverage. The insurance carriers recognize
                  this and price accordingly; for example, a clothing boutique
                  will not be charged the same liability rate as a demolition
                  contractor.
                </p>
                <h3>Commercial Property</h3>
                <p>
                  Unlike liability insurance - which protects your business from
                  others collecting damages from you - commercial property
                  insurance pays for your assets that are damaged.
                </p>
                <p>
                  These assets could range from your warehouse stock to the
                  building your business operates in. This insurance could even
                  cover lost income from a damaged or non-operational asset.
                </p>
                <p>
                  A broad array of buildings, equipment, and other items can be
                  covered under this type of SME insurance. Reach out to your
                  insurance broker if you have something specific that you are
                  interested in insuring.
                </p>
                <h3>Workers Compensation</h3>
                <p>
                  Unlike commercial liability or property insurance, workers
                  compensation insurance is required by most states if you have
                  employees.
                </p>
                <p>
                  It covers the medical bills that your employees receive if
                  they are injured while working for you. This could also
                  include writing them a paycheck while they are unable to
                  perform their duties at work.
                </p>
                <h3>Commercial Auto</h3>
                <p>
                  Commercial auto insurance provides coverage for business
                  liability arising out of the use of your business auto.
                  Commercial auto insurance will also pay for any repairs for
                  damage your vehicles take.
                </p>
                <p>
                  This is a necessary coverage for many businesses. We recommend
                  purchasing limits above your maximum possible loss due to the
                  potential severity of a large auto claim. This is especially
                  important if you use any large trucks for your business.
                </p>
              </div>
              <div className="right">
                {/* <!--Contact us--> */}
                <div className="card mb-4">
                  <img
                    src={needBusiness}
                    className="card-img-top"
                    alt="Business Insurance"
                  />
                  <div className="card-body">
                    <h5 className="card-title">SME Insurance</h5>
                    <p className="card-text">
                      You own a business and you need some specific business
                      insurance plan...
                    </p>
                    <Link to="/contacus" className="btn btn-primary">
                      Contact Us
                    </Link>
                  </div>
                </div>
                {/* <!--Contact us--> */}
                {/* <!--Insurance Market Stability--> */}
                <div className="profile-card card mb-4">
                  <img
                    src={marketChart}
                    className="card-img-top"
                    alt="Insurance Market Stability"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Insurance Market Stability</h5>
                    <div className="card-text">
                      <ul className="list-right-pointers">
                        <li>
                          The financial and management strength, attitude
                          towards claims and efficiency of the insurers is a
                          matter we take very seriously.
                        </li>
                        <li>
                          We conduct a review of all the insurance companies
                          annually and rate them, according to the Following
                          categories; Outstanding, A, B, C, D.
                        </li>
                        <li>
                          We ordinarily place our business with the insurance
                          companies in the outstanding, A, B & C categories.
                          Should a client prefer to have a placement in an
                          insurance company Rated otherwise we insist on a
                          letter of instruction.
                        </li>
                        <li>
                          This rating of insurance companies is not static and
                          we review it regularly on an on-going basis.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <!--Insurance Market Stability--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}