import { useEffect } from "react";

import OurServiceComp from "src/components/OurServiceComp";
import QuoteFormComp from "src/components/QuoteFormComp";


import healthInsur from 'src/assets/images/commercial_motor_white.svg';


export default function CommercialMotorProd(){
    document.title = 'Commercial Motor - FIS Ltd';

    useEffect(() => {    
        window.scrollTo(0, 0);
    }, []);

    return (
      <section>
        <div className="content-header">
          <div className="container">
            <div className="header-inner">
              <h3>Commercial Motor</h3>
              <img src={healthInsur} alt="" />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container">
            <div className="boddy-inner">
              <div className="left">
                <p>
                  Loss or damage –cost of repair or reinstatement following
                  accidental collision or overturning of the vehicle fir:
                  external explosion, self-ignition or lighting, burglary,
                  housebreaking or heft, malicious act, cost of protection
                  liability to third parties in death or bodily injury damage to
                  property litigation expenses with company's knowledge.
                </p>
              </div>
              <div className="right">
                {/* <!--Request quote--> */}
                <QuoteFormComp productName={"Commercial Motor Insurance"} />
                {/* <!--Request quote--> */}
                {/* <!--Our services--> */}
                <OurServiceComp />
                {/* <!--Our services--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}