const AppDeclarations = {
    currentLang: 'current_language',
    connectionStatus: 'connect_status',
    apiRootTest: 'https://api.fis.rw/api/',
    apiUrlTest: 'https://api.fis.rw/',

    userAccessKey: 'user_access_key',

    buyerAccessKey: 'buyer___access___key',

    company_code: 'WBCDTS79190723',
    currentYearbook: 'current_year_book',
}
export default AppDeclarations;