import { useEffect } from "react";

import OurServiceComp from "src/components/OurServiceComp";
import QuoteFormComp from "src/components/QuoteFormComp";


import healthInsur from 'src/assets/images/electronic_equipment.svg';

export default function ElectrEquipment(){
    document.title = 'Electrical Equipment - FIS Ltd';

    useEffect(() => {    
        window.scrollTo(0, 0);
    }, []);

    return (
      <section>
        <div className="content-header">
          <div className="container">
            <div className="header-inner">
              <h3>Electrical Equipment</h3>
              <img src={healthInsur} alt="" />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container">
            <div className="boddy-inner">
              <div className="left">
                <p>
                  This class of insurance provides cover for unforeseen and
                  sudden physical loss or damage to electronic data processing
                  machine, telecommunication equipment, office calculators,
                  duplicating machine and reproduction machine, signal and
                  transmitting units, electro-medical installations,
                  transmitting and receiving installations (radio, tv, cinema,
                  sound reproduction and studio).
                </p>
              </div>
              <div className="right">
                {/* <!--Request quote--> */}
                <QuoteFormComp productName={"Electronic Equipment Insurance"} />
                {/* <!--Request quote--> */}
                {/* <!--Our services--> */}
                <OurServiceComp />
                {/* <!--Our services--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}