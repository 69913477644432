import { useEffect } from 'react';

import officeBureau from 'src/assets/images/office_bureau.jpg';

import carVersion2 from 'src/assets/images/car_v2.svg';
import healthInsur from 'src/assets/images/health_insur.svg';
import bondsInsur from 'src/assets/images/bonds_ins.svg';
import marineInsur from 'src/assets/images/marine_ins.svg';
import fireInsur from 'src/assets/images/fire_ins.svg';
import burglaryInsur from 'src/assets/images/burglary.svg';
import goodsTransitInsur from 'src/assets/images/goods_transit.svg';
import groupAccidentInsu from 'src/assets/images/group_accident.svg';


import doubleMobile from 'src/assets/images/double_mobile.png';
import { Link } from 'react-router-dom';


export default function HomeApp(){
  document.title = 'Welcome - FIS Ltd';

  useEffect(() => {    
      window.scrollTo(0, 0);
  }, []);

    return (
      <section>
        {/* <!--Hero--> */}
        <div className="home-hero">
          <div className="container">
            <div className="hero-big-title">
              <div className="info-titles">
                <h4>The new art of Insurance</h4>
                <p>
                  Our customer Portal as well as our Support chat system are
                  always open 24h/24.
                </p>
                <p className="mb-4"></p>
                <div className="card card-portal">
                  <div className="card-body">
                    <h5 className="card-title">Customer's portal</h5>
                    <p className="card-text mb-4">
                      At FIS, you will be in good hands. We provide you with
                      proper tools to follow up your insurance portfolio.
                    </p>
                    <a
                      target="_blank"
                      href="http://customers.fis.rw"
                      className="btn btn-primary"
                      rel="noreferrer"
                    >
                      Go to portal
                    </a>
                  </div>
                </div>
              </div>
              <div className="info-web"></div>
            </div>
          </div>
        </div>
        {/* <!--Hero--> */}

        {/* <!--Mobile--> */}
        <div className="home-mobile">
          <div className="container">
            <div className="hero-big-title">
              <div className="row">
                <div className="col-md-6 display-text order-2 order-md-1">
                  <div className="info-titles">
                    <h4>250Insurances: Go mobile, go paperless</h4>
                    <p>
                      You no longer need to travel to buy insurance or report
                      any claim. Just a few taps.
                    </p>
                    <p className="mb-4"></p>
                    <div className="card card-portal">
                      <div className="card-body">
                        <h5 className="card-title">Different plaforms</h5>
                        <div className="card-text mb-1">
                          Whether you have an iOS or Android platform, feel free
                          to run it on your preferred operating system. The
                          world has evolved enough to remain behind. Simply tap
                          your mobile device and sign up for your insurance,
                          contact your insurer and find out the latest insurance
                          news in Rwanda and the region.
                          <br/>
                          <br/>

                          <ul className="lst-btn-mobile">
                          <li>
                            <a href='https://play.google.com/store/apps/details?id=com.wiseobjects.fis250insurances' className="download android">
                              <i className="fa fa fa-android fa-3x"></i>
                              <span className="df">Download from</span>
                              <span className="dfn">Google Play</span>
                            </a>
                          </li>
                          <li>
                            <a href='https://www.apple.com/app-store/' className="download apple">
                              <i className="fa fa fa-apple fa-3x"></i>
                              <span className="df">Download from</span>
                              <span className="dfn">App Store</span>
                            </a>
                          </li>
                        </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mobile-display order-1 order-md-2">
                  <img
                    src={doubleMobile}
                    alt=""
                    className="img-mobile-app android"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--Mobile--> */}

        {/* <!--welcome area--> */}
        <div className="welcome-area">
          <div className="container">
            <div className="welcome-content">
              <div className="row">
                <div className="col-md-6 box-flex center">
                  <img src={officeBureau} alt="" className="img" />
                </div>
                <div className="col-md-6">
                  <h5>
                    <i className="fas fa-building"></i> Who We Are
                  </h5>
                  <p>
                    Falcon Insurance Services is a leading provider of financial
                    and risk services. We are an independent fast growing
                    provider of these services, with niche dominance in selected
                    business areas. A significant network of partners ensures we
                    provide an outstanding level of service to our customers
                    internationally.
                  </p>
                  <p>
                    Falcon customers include small, medium and large
                    organizations, specialist groups and individuals. We focus
                    on our customers to deliver fast, efficient local service by
                    empowered people wherever we operate.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--welcome area--> */}

        {/* <!--quick-decisions--> */}
        <div className="quick-decisions">
          <div className="container">
            <div className="quick-inner">
              <h2>Good health, no burglary, no accident</h2>
              <h3>Ask yourself: How long will it last?</h3>
              <blockquote className="blockquote  text-center">
                <p className="mb-0">Chance favors the prepared mind.</p>
                <footer className="blockquote-footer">
                  <cite title="Louis Pasteur">Louis Pasteur</cite>
                </footer>
              </blockquote>
              <p className="buttons">
                <Link to="/contactus/" className="btn btn-primary btn-lg">
                  Contact Us <i className="fa fa-angle-right"></i>
                </Link>
                &nbsp;
                <a
                  href="http://250insurances.fis.rw"
                  target="_blank"
                  className="btn btn-success btn-lg"
                  rel="noreferrer"
                >
                  Go to Customer's portal <i className="fa fa-angle-right"></i>
                </a>
              </p>
            </div>
          </div>
        </div>
        {/* <!--quick-decisions--> */}

        {/* <!--some products--> */}
        <div className="some-products">
          <div className="container">
            <div className="some-products-content">
              <h2 className="text-block-title">
                Learn about some of our products and services
              </h2>
              <div className="products-container">
                <a href="/products/indivprods/motor" className="prod-preview">
                  <img
                    className="product-icon"
                    src={carVersion2}
                    alt="Motor private"
                  />
                  Private Motor
                </a>
                <a href="/products/indivprods/health" className="prod-preview">
                  <img
                    className="product-icon"
                    src={healthInsur}
                    alt="Health"
                  />
                  Health Insurance
                </a>
                <a href="/products/indivprods/bonds" className="prod-preview">
                  <img className="product-icon" src={bondsInsur} alt="Bonds" />
                  Bonds
                </a>
                <a href="/products/corpprods/marine" className="prod-preview">
                  <img
                    className="product-icon"
                    src={marineInsur}
                    alt="Marine"
                  />
                  Marine Insurance
                </a>
                <a href="/products/corpprods/fire" className="prod-preview">
                  <img className="product-icon" src={fireInsur} alt="Fire" />
                  Fire
                </a>
                <a href="/products/corpprods/burglary" className="prod-preview">
                  <img
                    className="product-icon"
                    src={burglaryInsur}
                    alt="Burglary"
                  />
                  Burglary
                </a>
                <a
                  href="/products/corpprods/goodstransit"
                  className="prod-preview"
                >
                  <img
                    className="product-icon"
                    src={goodsTransitInsur}
                    alt="Goods in transit"
                  />
                  Goods in Transit
                </a>
                <a
                  href="/products/corpprods/groupersonacc"
                  className="prod-preview"
                >
                  <img
                    className="product-icon"
                    src={groupAccidentInsu}
                    alt="Group accident"
                  />
                  Group Personal Accident
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <!--some products--> */}
      </section>
    );
}