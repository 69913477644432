import OurServiceComp from "src/components/OurServiceComp";
import QuoteFormComp from "src/components/QuoteFormComp";

import allRisksImg from 'src/assets/images/all_risks_white.svg';
import { useEffect } from "react";

export default function AllRisksProd(){
    document.title = 'All risks - FIS Ltd';

    useEffect(() => {    
        window.scrollTo(0, 0);
    }, []);

    return (
      <section>
        <div className="content-header">
          <div className="container">
            <div className="header-inner">
            <h3>All Risks</h3>
              <img src={allRisksImg} alt="" />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container">
            <div className="boddy-inner">
              <div className="left">
                <p>
                  This policy provides cover for loss of or damage to the
                  property insured by Fire, Theft or any Accidental means not
                  excluded by the Policy. It is suitable for mobile items like
                  cameras, laptops, personal computers, videos and other fragile
                  electronic items, personal items, baggage etc. Due to the
                  mobile nature of most items, cover will not be restricted to
                  the premises. Policy will usually have the territorial scope
                  as Kenya or Worldwide in respect to items such as laptops and
                  cameras.
                </p>
              </div>
              <div className="right">
                {/* <!--Request quote--> */}
                <QuoteFormComp productName={"All risks Insurance"} />
                {/* <!--Request quote--> */}
                {/* <!--Our services--> */}
                <OurServiceComp />
                {/* <!--Our services--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}