import { useEffect } from "react";

import OurServiceComp from "src/components/OurServiceComp";
import QuoteFormComp from "src/components/QuoteFormComp";


import healthInsur from 'src/assets/images/fire_guarantee.svg';


export default function Fire(){
    document.title = 'Fire - FIS Ltd';

    useEffect(() => {    
        window.scrollTo(0, 0);
    }, []);

    return (
      <section>
        <div className="content-header">
          <div className="container">
            <div className="header-inner">
                <h3>Fire</h3>
              <img src={healthInsur} alt="" />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container">
            <div className="boddy-inner">
              <div className="left">
                <p>
                  Provides indemnity at very reasonable cost for loss or damage
                  by fire lighting explosion of gas for illuminating or domestic
                  purposes and the following perils on application explosion in
                  which pressure is used, storm tempest, rain, flood, overflow
                  of guttering, bursting or overflowing of water apparatus,
                  aircraft & aerial devices, impact by road vehicles,
                  subterranean fire, earthquake fire & shock, bush fire, riot &
                  strike (malicious damage, connected therewith).
                </p>
              </div>
              <div className="right">
                {/* <!--Request quote--> */}
                <QuoteFormComp productName={"Fire Insurance"} />
                {/* <!--Request quote--> */}
                {/* <!--Our services--> */}
                <OurServiceComp />
                {/* <!--Our services--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}