import { useEffect } from 'react';

import companyProfile from 'src/assets/images/company_profile_v2.svg';
import officeVision from 'src/assets/images/office_vison.jpg';
import officeBlackSitting from 'src/assets/images/office_black_sitting.jpg';
import businessInitiatives from 'src/assets/images/business_initiatives.jpg';
import fireFigthers from 'src/assets/images/fire_fighers.jpg';


export default function CompanyAbout(){
    document.title = 'Company profile - FIS Ltd';

    useEffect(() => {    
        window.scrollTo(0, 0);
    }, []);

      
    return (
      <section>
        <div className="content-header">
          <div className="container">
            <div className="header-inner">
              <h3>Company profile</h3>
              <img src={companyProfile} alt="" />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container">
            <div className="boddy-inner">
              <div className="left">
                <h4>Introduction</h4>
                <p>
                  Falcon Insurance Services is a leading provider of financial
                  and risk services. We are an independent fast growing provider
                  of these services, with niche dominance in selected business
                  areas.
                </p>
                <p>
                  A significant network of partners ensures we provide an
                  outstanding level of service to our customers internationally.
                  Falcon customers include small, medium and large
                  organizations, specialist groups and individuals. We focus on
                  our customers to deliver fast, efficient local service by
                  empowered people wherever we operate.
                </p>
                <p>
                  At Falcon Insurance Services, we work to simplify the way to
                  buy insurance policy plan. Our dedicated client managers help
                  you get insurance while giving you a chance to compare
                  insurance policies before making a final decision.
                </p>
                <p>
                  Our team of experts suggests the best insurance policy that
                  covers your damage/loss while allowing you to save tax by
                  finding the right policy plan. We at Falcon Insurance Services
                  aim to simplify the intricacies of buying insurance. Compare
                  insurance policies and get the right insurance plan.
                </p>
                <p>
                  After gaining the reputation of being a trustworthy company to
                  deal with in other fields, Falcon Insurance Services, the
                  leading discount broker in Rwanda, decided to venture into the
                  insurance industry with a complete technology-driven work
                  approach. We offer you a transparent online platform and
                  paperless process to buy insurance policy plan.
                </p>

                {/* <!--What we offer--> */}
                <div className="profile-offer">
                  <h4>What we offer</h4>

                  <div className="offer-type-container">
                    <div className="offer-box">
                      <h4>Financial Services</h4>
                      <ul>
                        <li>Retirement Fund Administration &amp; Consulting</li>
                        <li>Actuarial Services</li>
                        <li>Healthcare Consulting</li>
                      </ul>
                    </div>
                    <div className="offer-box">
                      <h4>Risk Services</h4>
                      <ul>
                        <li>
                          Risk & Insurance Programme Management &amp; Consulting
                        </li>
                        <li>
                          Alternative Risk Finance Consulting &amp; Facilities
                        </li>
                        <li>Claims Management</li>
                        <li>
                          Insurance Products &amp; Services for Individuals
                        </li>
                        <li>Insurance &amp; Reinsurance Broking</li>
                      </ul>
                    </div>
                    <div className="offer-box">
                      <h4>We Ensure</h4>
                      <ul>
                        <li>One Stop-Shop</li>
                        <li>Consistency of Cover and Limits</li>
                        <li>
                          Dovetail your covers with Global Programme(s) where
                          applicable
                        </li>
                        <li>Security of Insurance Carriers</li>
                        <li>Specialist Resources and as Point of Contact</li>
                      </ul>
                    </div>
                    <div className="offer-box">
                      <h4>Plus</h4>
                      <ul>
                        <li>Ability to meetclients’ needs</li>
                        <li>Local knowledge andcontacts</li>
                        <li>Access to Groupresources</li>
                        <li>Competent and motivatedpeople</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <!--What we offer--> */}
                {/* <!--our phylosohy--> */}
                <div className="profile-offer">
                  <h4>Our Phylosophy</h4>
                  <div className="offer-type-container">
                    <div className="offer-box">
                      <ul>
                        <li>
                          We are a local company but our focus is on fast,
                          efficient service to our clients.
                        </li>
                        <li>
                          We listen to our clients. We start with an
                          understanding of each client's needs and objectives.
                          Adding value is our objective for every client.
                        </li>
                        <li>
                          We are small and flexible enough to provide unique
                          solutions to each of our client's needs and
                          requirements but belong to a large enough network of
                          Global brokers to provide the best technical skills
                          and resources.
                        </li>
                      </ul>
                    </div>

                    <div className="offer-box">
                      <ul>
                        <li>
                          We employ top quality people with a wide variety of
                          professional skills.
                        </li>
                        <li>
                          Through leading edge technology, we share these skills
                          worldwide.
                        </li>
                        <li>
                          Our people are enthusiastic, energetic, experienced
                          and totally committed to excellent, client service.
                        </li>
                        <li>
                          We build long-term relationships with clients, with
                          insurers, with re-insurers and with Investment and
                          fund managers. In every relationship we act with
                          honesty and integrity.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                {/* <!--Vision--> */}
                <div className="profile-card card mb-4">
                  <img
                    src={officeVision}
                    className="card-img-top"
                    alt="Our vision"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Our Vision</h5>
                    <p className="card-text">
                      To be a lead Financial and Risk services provider in
                      Africa.
                    </p>
                  </div>
                </div>
                {/* <!--Vision--> */}
                {/* <!--Our Mission--> */}
                <div className="profile-card card mb-4">
                  <img
                    src={officeBlackSitting}
                    className="card-img-top"
                    alt="Our mission"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Our Mission</h5>
                    <p className="card-text">
                      To provide efficiently innovative products and services to
                      all clients with the highest degree of efficiency and
                      integrity.
                    </p>
                  </div>
                </div>
                {/* <!--Our Mission--> */}
                {/* <!--Initiatives--> */}
                <div className="profile-card card mb-4">
                  <img
                    src={businessInitiatives}
                    className="card-img-top"
                    alt="Business initiatives and offerings"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Initiatives &amp; Offerings</h5>
                    <div className="card-text">
                      <ul className="list-right-pointers">
                        <li>
                          To build strong and long-term business chains between
                          Customers and Suppliers.
                        </li>
                        <li>
                          To add value - based on our market knowledge,
                          efficiency and reliability
                        </li>
                        <li>
                          To offer business and networking opportunities -
                          within a global network
                        </li>
                        <li>
                          To work with a global approach and find optimal
                          solutions in each individual business agreement.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <!--Initiatives--> */}
                {/* <!--Initiatives--> */}
                <div className="profile-card card mb-4">
                  <img
                    src={fireFigthers}
                    className="card-img-top"
                    alt="Service Capability"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Service Capability</h5>
                    <div className="card-text">
                      <ul className="list-right-pointers">
                        <li>
                          Most Extensive Network, represented in over 200
                          Principal countries and expanding, with a wide network
                          of correspondent offices on the continent.
                        </li>
                        <li>
                          Reputation for Quality Service & Staff. We are well
                          endowed with professionals equipped to provide our
                          clients quality service. There is significant number
                          of highly suitably qualified staff.
                        </li>
                        <li>
                          We are keeping pace with the information technology
                          and use modern insurance packages focused on the
                          client to give them quality and efficient service.
                        </li>
                        <li>
                          We integrate the Risk Programme with existing
                          operations, insurance and corporate strategic
                          objectives.
                        </li>
                        <li>
                          We always ensure that our service is on the leading
                          edge of developments in the field of risk.
                        </li>
                        <li>Leaders in Technology.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <!--Initiatives--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}