import { useEffect } from "react";

import OurServiceComp from "src/components/OurServiceComp";
import QuoteFormComp from "src/components/QuoteFormComp";


import healthInsur from 'src/assets/images/car_ins_white.svg';

export default function Motor(){
    document.title = 'Motor Insurance - FIS Ltd';

    useEffect(() => {    
        window.scrollTo(0, 0);
    }, []);

    return (
      <section>
        <div className="content-header">
          <div className="container">
            <div className="header-inner">
              <h3>Motor Insurance</h3>
              <img src={healthInsur} alt="" />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container">
            <div className="boddy-inner">
              <div className="left">
                <p>
                  Loss or damage - cost of repairs or reinstatement follow
                  accident collision or overturning of the vehicle, fire,
                  external explosion, self-ignition or lighting, burglary,
                  housebreaking or theft malicious damage, cost of protection
                  and removal to repairs following damage in transit by road
                  transport, rail inland waterway, lift or elevator, liability
                  to third parties - death or bodily injury, damage to property,
                  medical expenses arising from an accident to the vehicle but
                  not exceeding the limit stated.
                </p>
              </div>
              <div className="right">
                {/* <!--Request quote--> */}
                <QuoteFormComp productName={"Motor Private Insurance"} />
                {/* <!--Request quote--> */}
                {/* <!--Our services--> */}
                <OurServiceComp />
                {/* <!--Our services--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}