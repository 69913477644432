import { useEffect, useState } from "react";
import { generalFx } from "src/utils/general_fxs";
import FormgroupInput from "./FormgroupInput";
import NetServices from "src/utils/netservices";
import { ProdeQteInter } from "src/models/netreturn";


interface QuoteFormInter {
    productName: 
        'Health Isnurance' | 'Motor Private Insurance' | 'Domestic Package Insurance' | 'Personal Accident Insurance' | 
        'Medical Insurance' | 'Professional Indemnity Insurance' | 'Goods in Transit / Individual' | 'Bonds Insurance' | 
        'Fire Insurance' | 'Bulgary Insurance' | 'All risks Insurance' | 'Money Insurance' | 'Fidelity Guarantee Insurance' |
        'Goods in Transit' | 'Commercial Motor Insurance' | 'Group Personal Accident Insurance' | 'Marine Insurance' | 
        'Contractors All Risks Insurance' | 
        'Work Injury Benefit Act Insurance' | 'Glass Insurance' | 'Public Liability Insurance' | 
        'Professional Indemnity Insurance' | 'Electronic Equipment Insurance' | 'Consequential Loss Insurance',
}
const emptyRecord:ProdeQteInter = {
    ProductRequestInfoId: 0,
    FirstName: '',
    LastName: '',
    FullAddress: '',
    PhoneNumber: '',
    EmailAddress: '',
    ProductName: 'Healt Isnurance',
    Message: '',
    CanReceiveMonthlyEmail: false,
    DateRequest: new Date(),
    DateReceived: new Date(),
    DateToBeContacted: new Date(),
    SecureCode: '',
};

export default function QuoteFormComp({productName}: QuoteFormInter) {
    const [formData, setFormData] = useState<ProdeQteInter>(emptyRecord);
    const [captchaValue, setCaptchaValue] = useState<string>('');

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccessSent, setIsSuccessSent] = useState(false);
    const [isErrorFromServer, setIsErrorFromServer] = useState(false);
    

    const isValidForm = () => {
        return formData.FirstName && formData.FirstName.trim() !== '' &&
        formData.LastName && formData.LastName.trim() !== '' &&
        formData.FullAddress && formData.FullAddress.trim() !== '' &&
        formData.PhoneNumber && formData.PhoneNumber.trim() !== '' && formData.PhoneNumber.length === 12 && 
        formData.SecureCode && formData.SecureCode.trim() !== '' && formData.SecureCode.length === 5 && 
        formData.EmailAddress && formData.EmailAddress.trim() !== '' && generalFx.isEmailValid(formData.EmailAddress) &&
        formData.Message && formData.Message.trim() !== '';
    }
    const checkCaptchaValidProd = () => {
        return formData.SecureCode === captchaValue;
    }

    const sendInsuranceRequestProdData = async() =>  {
        setIsSubmitting(true);
        setIsErrorFromServer(false);
        setIsSuccessSent(false);

        const oFinalRecord = {
            ...formData, 
            ProductName: productName,
            DateRequest: generalFx.dateToUtcStr(formData.DateRequest),
            DateReceived: generalFx.dateToUtcStr(formData.DateReceived),
            DateToBeContacted: generalFx.dateToUtcStr(formData.DateToBeContacted),
            SecureCode: captchaValue,
        };

        const oData = {
            'productRequestInfo': oFinalRecord,
        };

        const retData = await NetServices.requestPost('web/sendrequestproduct', oData);

        if(!retData.bReturn){
            generalFx.showAlert(retData.msgTitle, retData.msgBody);
            setIsSubmitting(false);
            setIsErrorFromServer(true);
            setIsSuccessSent(false);
            return;
        }

        document.location = '/'
    }

    useEffect(() => {    
        setCaptchaValue(generalFx.randomCharsFormInts(5));
    }, []);

  return (
    <div className="request-quote mb-4">
      <h2>Request a quote</h2>
      <small>Once received, we'll get back to you</small>
      <span id="spanProductName" style={{ display: "none" }}>
        Health Insurance
      </span>
      <form
        className="quote-req mt-3"
        name="frmProduct"
        autoComplete="disabled"
      >
        <FormgroupInput
          type="text"
          componentId="FirstName"
          placeholder="first name"
          value={formData.FirstName}
          key="FisrtName"
          hasError={!formData.FirstName && formData.FirstName.trim() === ""}
          onChange={(e) => {
            setFormData({
              ...formData,
              FirstName: e.target.value,
            });
          }}
        />

        <FormgroupInput
          type="text"
          componentId="LastName"
          placeholder="last name"
          value={formData.LastName}
          key="LastName"
          hasError={!formData.LastName && formData.LastName.trim() === ""}
          onChange={(e) => {
            setFormData({
              ...formData,
              LastName: e.target.value,
            });
          }}
        />

        <FormgroupInput
          type="text"
          isTextArea={true}
          componentId="FullAddress"
          placeholder="full address"
          value={formData.FullAddress}
          key="FullAddress"
          hasError={!formData.FullAddress || formData.FullAddress.trim() === ""}
          onChangeArea={(e) => {
            setFormData({
              ...formData,
              FullAddress: e.target.value,
            });
          }}
          maxLength={500}
        />

        <FormgroupInput
          type="email"
          componentId="EmailAddress"
          placeholder="email"
          value={formData.EmailAddress}
          key="EmailAddress"
          hasError={
            !formData.EmailAddress ||
            formData.EmailAddress.trim() === "" ||
            !generalFx.isEmailValid(formData.EmailAddress)
          }
          onChange={(e) => {
            setFormData({
              ...formData,
              EmailAddress: e.target.value,
            });
          }}
        />

        <FormgroupInput
          type="text"
          isNumericFld={true}
          componentId="PhoneNumber"
          placeholder="phone number (250---------)"
          value={formData.PhoneNumber}
          key="PhoneNumber"
          maxLength={12}
          hasError={
            !formData.PhoneNumber ||
            formData.PhoneNumber.trim() === "" ||
            formData.PhoneNumber.length !== 12
          }
          onChange={(e) => {
            setFormData({
              ...formData,
              PhoneNumber: e.target.value,
            });
          }}
        />

        <FormgroupInput
          type="text"
          isTextArea={true}
          componentId="Message"
          placeholder="message"
          value={formData.Message}
          key="Message"
          hasError={!formData.Message || formData.Message.trim() === ""}
          onChangeArea={(e) => {
            setFormData({
              ...formData,
              Message: e.target.value,
            });
          }}
          maxLength={500}
        />

        <div className="recaptcha-box">
          <div className="cover">
            <input
              type="text"
              id="SecureCode"
              name="SecureCode"
              autoComplete="off"
              maxLength={5}
              minLength={5}
              className="number"
              onChange={(e) => {
                setFormData({
                    ...formData,
                    SecureCode: e.target.value,
                  });
              }}
              value={formData.SecureCode}
            />
            <div className="cap-photo">
              {captchaValue}
              <div className="hide"></div>
            </div>
          </div>
        </div>

        <button
          type="button"
          className="btn mt-3 mb-3"
          disabled={!isValidForm() || isSubmitting || !checkCaptchaValidProd()}
          onClick={sendInsuranceRequestProdData}
        >
            {!isSubmitting ? <span>Send</span>: null}
            {isSubmitting ? <><span>Sending insurance request email</span>&nbsp;&nbsp;</>: null}
            {isSuccessSent && !isSubmitting ? (<><span>Email sent with success!</span>&nbsp;</>): null}
            {isSubmitting ? <i ng-if="isSubmitting" className="fa fa-spinner fa-spin"></i>: null}          
        </button>

        {isErrorFromServer ? <span className="formError">Error from server. Please check your connection</span>: null}
        
        <div className="text-center mt-1">
          <small className="text-secondary prod">
            By submitting this form, I agree to FIS's&nbsp;
            <a href="/legal/terms" target="_blank" rel="noopener noreferrer">
              Terms of Use
            </a>&nbsp;
            and&nbsp;
            <a href="/legal/privacy" target="_blank" rel="noopener noreferrer">              
              Privacy Policy
            </a>.
          </small>
        </div>
      </form>
    </div>
  );
}
