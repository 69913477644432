import { useEffect } from "react";

import OurServiceComp from "src/components/OurServiceComp";
import QuoteFormComp from "src/components/QuoteFormComp";


import healthInsur from 'src/assets/images/work_injury.svg';

export default function InjuryBenefit(){
    document.title = 'Work Injury Benefit Act - FIS Ltd';

    useEffect(() => {    
        window.scrollTo(0, 0);
    }, []);

    return (
      <section>
        <div className="content-header">
          <div className="container">
            <div className="header-inner">
              <h3>Work Injury Benefit Act</h3>
              <img src={healthInsur} alt="" />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container">
            <div className="boddy-inner">
              <div className="left">
                <p>
                  The policy provides cover for liability by law if an employee
                  in insured's service sustains bodily injury arising out of and
                  in the course of employment by the insured in the business.
                </p>
              </div>
              <div className="right">
                {/* <!--Request quote--> */}
                <QuoteFormComp
                  productName={"Work Injury Benefit Act Insurance"}
                />
                {/* <!--Request quote--> */}
                {/* <!--Our services--> */}
                <OurServiceComp />
                {/* <!--Our services--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}