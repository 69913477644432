import { useEffect } from "react";

import OurServiceComp from "src/components/OurServiceComp";
import QuoteFormComp from "src/components/QuoteFormComp";


import healthInsur from 'src/assets/images/fidelity_card.svg';


export default function FidGuar(){
    document.title = 'Consequential Loss - FIS Ltd';

    useEffect(() => {    
        window.scrollTo(0, 0);
    }, []);

    return (
      <section>
        <div className="content-header">
          <div className="container">
            <div className="header-inner">
                <h3>Fidelity Guarantee</h3>
              <img src={healthInsur} alt="" />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container">
            <div className="boddy-inner">
              <div className="left">
                <p>
                  The policy provides cover for loss of money and / or goods of
                  the insured / employer as a result of fraud or dishonesty
                  committed by any one of those employed in connection with
                  his/her occupation and duties.
                </p>
              </div>
              <div className="right">
                {/* <!--Request quote--> */}
                <QuoteFormComp productName={"Fidelity Guarantee Insurance"} />
                {/* <!--Request quote--> */}
                {/* <!--Our services--> */}
                <OurServiceComp />
                {/* <!--Our services--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}