import { useEffect } from "react";

import OurServiceComp from "src/components/OurServiceComp";
import QuoteFormComp from "src/components/QuoteFormComp";


import healthInsur from 'src/assets/images/conseq_loss.svg';

export default function ConseqLoss(){
    document.title = 'Consequential Loss - FIS Ltd';

    useEffect(() => {    
        window.scrollTo(0, 0);
    }, []);

    return (
      <section>
        <div className="content-header">
          <div className="container">
            <div className="header-inner">
              <h3>Consequential Loss</h3>
              <img src={healthInsur} alt="" />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container">
            <div className="boddy-inner">
              <div className="left">
                <p>
                  This form of insurance concerned with paying for the
                  consequences of a material damage loss. When the property is
                  used for business purposes, the trader due to damage looses
                  his productive capacity or future earning power which is of
                  paramount importance to him. The normal business activities
                  may even cease or be curtailed drastically and the gross
                  profit reduced or eliminated. The Consequential Loss Insurance
                  therefore enables the Insured to recover gross profit due to
                  the reduction in turnover and reasonable additional
                  expenditure incurred in minimizing the loss.
                </p>
              </div>
              <div className="right">
                {/* <!--Request quote--> */}
                <QuoteFormComp productName={"Consequential Loss Insurance"} />
                {/* <!--Request quote--> */}
                {/* <!--Our services--> */}
                <OurServiceComp />
                {/* <!--Our services--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}