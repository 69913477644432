import { useEffect } from "react";


import smeInsurProds from 'src/assets/images/sme_insurance.svg';
import indivProds from 'src/assets/images/individual_products_v2.jpg';

import marketChart from 'src/assets/images/market_chart.jpg';
import { Link } from "react-router-dom";

export default function IndivProds(){
    document.title = 'Individual Insurance products - FIS Ltd';

    useEffect(() => {    
        window.scrollTo(0, 0);
    }, []);
    
    return (
      <section>
        <div className="content-header">
          <div className="container">
            <div className="header-inner">
              <h3>
                <span>Individual</span> Insurance products
              </h3>
              <img src={smeInsurProds} alt="" />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container">
            <div className="boddy-inner">
              <div className="left">
                <img
                  className="w-100 mb-2"
                  src={indivProds}
                  alt="Individual Insurance Products"
                />
                <h3 className="main-color">
                  List of Individual Insurance Products
                </h3>
                <ul className="list-right-pointers mb-4">
                  <li>
                    <Link to="/products/indivprods/health">Health Insurance</Link>
                  </li>
                  <li>
                    <Link to="/products/indivprods/motor">Motor Private</Link>
                  </li>
                  <li>
                    <Link to="/products/indivprods/domestic">Domestic Package</Link>
                  </li>
                  <li>
                    <Link to="/products/indivprods/allrisks">All Risks</Link>
                  </li>
                  <li>
                    <Link to="/products/indivprods/persacc">Personal Accident</Link>
                  </li>
                  <li>
                    <Link to="/products/indivprods/medinsur">
                      Medical Insurance
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/indivprods/profindemn">
                      Professional Indemnity
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/indivprods/goodstransit">
                      Goods in Transit
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/indivprods/bonds">Bonds</Link>
                  </li>
                </ul>
              </div>
              <div className="right">
                {/* <!--Insurance Market Stability--> */}
                <div className="profile-card card mb-4">
                  <img
                    src={marketChart}
                    className="card-img-top"
                    alt="Insurance Market Stability"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Insurance Market Stability</h5>
                    <div className="card-text">
                      <ul className="list-right-pointers">
                        <li>
                          The financial and management strength, attitude
                          towards claims and efficiency of the insurers is a
                          matter we take very seriously.
                        </li>
                        <li>
                          We conduct a review of all the insurance companies
                          annually and rate them, according to the Following
                          categories; Outstanding, A, B, C, D.
                        </li>
                        <li>
                          We ordinarily place our business with the insurance
                          companies in the outstanding, A, B & C categories.
                          Should a client prefer to have a placement in an
                          insurance company Rated otherwise we insist on a
                          letter of instruction.
                        </li>
                        <li>
                          This rating of insurance companies is not static and
                          we review it regularly on an on-going basis.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <!--Insurance Market Stability--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}