import { useEffect } from "react";

import OurServiceComp from "src/components/OurServiceComp";
import QuoteFormComp from "src/components/QuoteFormComp";


import healthInsur from 'src/assets/images/publ_liability.svg';




export default function PublicLiab(){
    document.title = 'Public Liability - FIS Ltd';

    useEffect(() => {    
        window.scrollTo(0, 0);
    }, []);

    return (
      <section>
        <div className="content-header">
          <div className="container">
            <div className="header-inner">
              <h3>Public Liability</h3>
              <img src={healthInsur} alt="" />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container">
            <div className="boddy-inner">
              <div className="left">
                <p>
                  The policy provides cover for legal liability in respect of
                  bodily injury to or illness of any person and loss or damage
                  to property belonging to third parties arising as a result of:
                </p>
                <div>
                  <ul className="list-right-pointers">
                    <li>
                      Defects in the insured's ways, works machinery or plant.
                    </li>
                    <li>
                      Negligence of the insured or his employee while on duty.
                    </li>
                    <li>Defective product.</li>
                  </ul>
                </div>
              </div>
              <div className="right">
                {/* <!--Request quote--> */}
                <QuoteFormComp
                  productName={"Public Liability Insurance"}
                />
                {/* <!--Request quote--> */}
                {/* <!--Our services--> */}
                <OurServiceComp />
                {/* <!--Our services--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}