import { useEffect } from "react";

import OurServiceComp from "src/components/OurServiceComp";
import QuoteFormComp from "src/components/QuoteFormComp";


import healthInsur from 'src/assets/images/indemnity.svg';

export default function ProfIndemn(){
    document.title = 'Professional Indemnity - FIS Ltd';

    useEffect(() => {    
        window.scrollTo(0, 0);
    }, []);

    return (
      <section>
        <div className="content-header">
          <div className="container">
            <div className="header-inner">
              <h3>Professional Indemnity</h3>
              <img src={healthInsur} alt="" />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container">
            <div className="boddy-inner">
              <div className="left">
                <p>
                  The Professional Indemnity Policy covers awards arising out of
                  court judgments, defense costs, fees, expenses and loss of
                  documents.
                </p>
                <p>
                  This policy protects professionals from legal liability for
                  any acts of negligence they may commit in the course their
                  duty. You do not have to be wrong to be sued, the policy will
                  provide for the costs of defending any action brought against
                  the insured in their professional capacity.
                </p>
              </div>
              <div className="right">
                {/* <!--Request quote--> */}
                <QuoteFormComp productName={"Professional Indemnity Insurance"} />
                {/* <!--Request quote--> */}
                {/* <!--Our services--> */}
                <OurServiceComp />
                {/* <!--Our services--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}