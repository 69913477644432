import officeWorking from 'src/assets/images/office_working_sessions.jpg';

export default function OurServiceComp() {
  return (
    <div className="profile-card card mb-4">
      <img
        src={officeWorking}
        className="card-img-top"
        alt="Business initiatives and offerings"
      />
      <div className="card-body">
        <h5 className="card-title">Our services include</h5>
        <div className="card-text">
          <ul className="list-right-pointers">
            <li>
              Placement of the insurance Programme - We source for the most
              competitive terms in the market. We ensure that insurers provide
              the most extensive coverage available, to ensure no gaps in the
              cover. Policy documents are scrutinized to ensure compliance.
            </li>
            <li>
              Handling all claims - We advise on the documentation and
              information required and procedures.
            </li>
            <li>
              We value all our clients and therefore maintain close contact with
              them. Regular visits are a feature of our working relationship and
              these are made in consultation with the clients.
            </li>
            <li>
              On Group Life covers, we negotiate very competitive rates and
              realistic free cover limits to avoid subjecting staff members to
              unnecessary medical testing.
            </li>
            <li>
              Medical Insurance is a specialized unit that handles medical
              covers, whether the benefits are insured or self-funded. We also
              arrange schemes that may be partly self-funded and partly insured
              to optimize the clients' expenditure in this area. The department
              is fully computerized ensuring speedy turn around on claims.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
