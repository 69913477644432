import { useEffect } from 'react';

import staffIcon from 'src/assets/images/staff_icon.svg';
import valensHabimana from 'src/assets/images/profile/valens_habimana.png';
import philipeRubega from 'src/assets/images/profile/philip_rubega_rutabazwa.png';
import alexisBir from 'src/assets/images/profile/alexis_birindabagabo.png';

import officeWorking from 'src/assets/images/office_working_sessions.jpg';

export default function StaffAbout(){
    document.title = 'Management staff - FIS Ltd';

    useEffect(() => {    
        window.scrollTo(0, 0);
    }, []);


    return (
      <section>
        <div className="content-header">
          <div className="container">
            <div className="header-inner">
              <h3>Management Staff</h3>
              <img src={staffIcon} alt="" />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container">
            <div className="boddy-inner">
              <div className="left">
                <div className="person">
                  {/* <!--Valens Habimana--> */}
                  <div className="person-profile">
                    <div className="img-profile">
                      <img src={valensHabimana} alt="" />
                    </div>
                    <div className="user-names">
                      <p className="name">Valens HABIMANA</p>
                      <p className="job">Managing Director</p>
                    </div>
                    <div className="user-details">
                      <p>
                        <span className="short-name">Valens</span>, has more
                        than 15 years experience in the insurance operations
                        industry. He began his areer with COGEAR (Compagnie
                        Rwandaise d'Assurances au Rwanda) before joining BGCR
                        DRC, SCAR-DRC-UTMOST Insurance Ltd as a MAnager in
                        charge of Business Development and Chief Underwriting,
                        Falcon Insurance Services Ltd as Client Relationship
                        Executive. He holds a MBA in Risk Management-Insurance
                        from Ecole Supérieure d'Assurances de Paris-France.
                      </p>
                    </div>
                  </div>
                  {/* <!--Valens Habimana--> */}
                  {/* <!--Philip Rubega Rutabuzwa--> */}
                  <div className="person-profile">
                    <div className="img-profile">
                      <img src={philipeRubega} alt="" />
                    </div>
                    <div className="user-names">
                      <p className="name">Philip Rubega Rutabuzwa</p>
                      <p className="job">Technical Advisor</p>
                    </div>
                    <div className="user-details">
                      <p>
                        <span className="short-name">Philip</span>, the
                        Technical Advisor of FIS and Member of Business
                        Development Executive of the Company, as you know this
                        is high personalized services, it requires a selected
                        discipline. Philip will be setting on technical aspects
                        advising the operations team. He has a regional and
                        corporate hands-on-skills in the insurance brokerage
                        industry. He worked from different insurance
                        companies/providers in Rwanda, Uganda and Kenya.
                      </p>
                    </div>
                  </div>
                  {/* <!--Philip Rubega Rutabuzwa--> */}
                  {/* <!--Amina UWERA--> */}
                  <div className="person-profile" style={{ display: "none" }}>
                    <div className="img-profile">
                      <img
                        src="/assets/images/profile/amina_uwera.png"
                        alt=""
                      />
                    </div>
                    <div className="user-names">
                      <p className="name">Amina UWERA</p>
                      <p className="job">
                        Administration and Finance Assistant
                      </p>
                    </div>
                    <div className="user-details">
                      <p>
                        <span className="short-name">Amina</span>, with 5 years
                        in company, serving on several posts including Client
                        Relationship Assistant and Administration and Finance
                        Assistant.
                      </p>
                    </div>
                  </div>
                  {/* <!--Amina UWERA--> */}
                  {/* <!--Alexis BIRINDABAGABO--> */}
                  <div className="person-profile">
                    <div className="img-profile">
                      <img src={alexisBir} alt="" />
                    </div>
                    <div className="user-names">
                      <p className="name">Alex BIRINDABAGABO</p>
                      <p className="job">Operation Manager</p>
                    </div>
                    <div className="user-details">
                      <p>
                        <span className="short-name">Alex</span> is in charge of
                        Operations (Underwriting and Claims) with Marketing
                        knowledge in brokerage industry.
                      </p>
                    </div>
                  </div>
                  {/* <!--Alexis BIRINDABAGABO--> */}
                  {/* <!--Emmy KAGAME--> */}
                  <div className="person-profile" style={{ display: "none" }}>
                    <div className="img-profile">
                      <img
                        src="/assets/images/profile/emmy_kagame.png"
                        alt=""
                      />
                    </div>
                    <div className="user-names">
                      <p className="name">Emmy KAGAME</p>
                      <p className="job">Business Development Manager</p>
                    </div>
                    <div className="user-details">
                      <p>
                        <span className="short-name">Emmy</span> has insurance
                        industry track records both side, brokerage and
                        insurance companies. He served as Corporate Sales and
                        Marketing Manager.
                      </p>
                    </div>
                  </div>
                  {/* <!--Emmy KAGAME--> */}
                </div>
              </div>
              <div className="right">
                <div className="profile-card card mb-4">
                  <img
                    src={officeWorking}
                    className="card-img-top"
                    alt="Business initiatives and offerings"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Our services include</h5>
                    <div className="card-text">
                      <ul className="list-right-pointers">
                        <li>
                          Placement of the insurance Programme - We source for
                          the most competitive terms in the market. We ensure
                          that insurers provide the most extensive coverage
                          available, to ensure no gaps in the cover. Policy
                          documents are scrutinized to ensure compliance.
                        </li>
                        <li>
                          Handling all claims - We advise on the documentation
                          and information required and procedures.
                        </li>
                        <li>
                          We value all our clients and therefore maintain close
                          contact with them. Regular visits are a feature of our
                          working relationship and these are made in
                          consultation with the clients.
                        </li>
                        <li>
                          On Group Life covers, we negotiate very competitive
                          rates and realistic free cover limits to avoid
                          subjecting staff members to unnecessary medical
                          testing.
                        </li>
                        <li>
                          Medical Insurance is a specialized unit that handles
                          medical covers, whether the benefits are insured or
                          self-funded. We also arrange schemes that may be
                          partly self-funded and partly insured to optimize the
                          clients' expenditure in this area. The department is
                          fully computerized ensuring speedy turn around on
                          claims.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}