import { useEffect } from 'react';


import boardDirectors from 'src/assets/images/board_directors.svg';
import philipeRubega from 'src/assets/images/profile/philip_rubega_rutabazwa.png';
import valensHabimana from 'src/assets/images/profile/valens_habimana.png';


import marketChart from 'src/assets/images/market_chart.jpg';
import marketChoice from 'src/assets/images/market_choice.jpg';

export default function BoardAbout(){
    document.title = 'Board of directors - FIS Ltd';

    useEffect(() => {    
        window.scrollTo(0, 0);
    }, []);



    return (
      <section>
        <div className="content-header">
          <div className="container">
            <div className="header-inner">
              <h3>Board of Directors</h3>
              <img src={boardDirectors} alt="" />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container">
            <div className="boddy-inner">
              <div className="left">
                {/* <!--Bob Gatera--> */}
                <div className="board-member">
                  <div className="b-person">
                    <div className="person-profile">
                      <div className="img-profile">
                        <img
                          src={philipeRubega}
                          alt="Bob Gatera"
                        />
                      </div>
                      <div className="user-names">
                        <p className="name">Bob Gatera</p>
                        <p className="job">Chairman</p>
                      </div>
                    </div>
                  </div>
                  <div className="b-details">
                    <p>
                      <span className="short-name">Bob</span> is the General
                      Manager of Balton Rwanda, a British Multinational trading
                      in agriculture across the board, public health products,
                      electro-mechanical turnkey projects and communication
                      solutions.
                    </p>
                    <p>
                      He previously served as Head of Investment with the Rwanda
                      Investment Promotion Agency (RIEPA), now the Rwanda
                      Development Board (RDB).{" "}
                      <span className="short-name">Bob</span> holds an MBA from
                      the University of Kent (Canterbury, UK) and a Bachelor of
                      Business Administration from Makerere University, Uganda.
                      Bob is a founding member and a Board Member of Falcon
                      Capital Investments.
                    </p>
                  </div>
                </div>
                {/* <!--Bob Gatera--> */}
                {/* <!--NTARE YA SANO Jean Paul--> */}
                <div className="board-member">
                  <div className="b-person">
                    <div className="person-profile">
                      <div className="img-profile">
                        <img
                          src={philipeRubega}
                          alt="NTARE YA SANO Jean Paul"
                        />
                      </div>
                      <div className="user-names">
                        <p className="name">NTARE YA SANO Jean Paul</p>
                        <p className="job">Director</p>
                      </div>
                    </div>
                  </div>
                  <div className="b-details">
                    <p>
                      <span className="short-name">Jean Paul</span> is a Lawyer
                      by Professional and active member of Kigali Chamber of Bar
                      Association with extensive handonskils to settle corporate
                      Legal Files.
                    </p>
                  </div>
                </div>
                {/* <!--NTARE YA SANO Jean Paul--> */}
                {/* <!--Valens HABIMANA--> */}
                <div style={{ display: "none" }} className="board-member">
                  <div className="b-person">
                    <div className="person-profile">
                      <div className="img-profile">
                        <img
                          src={valensHabimana}
                          alt="Valens HABIMANA"
                        />
                      </div>
                      <div className="user-names">
                        <p className="name">Valens HABIMANA</p>
                        <p className="job">Chief Executive Officer</p>
                      </div>
                    </div>
                  </div>
                  <div className="b-details">
                    <p>
                      <span className="short-name">Valens</span>, has more than
                      15 years experience in the insurance operations industry.
                      He began his areer with COGEAR (Compagnie Rwandaise
                      d'Assurances au Rwanda) before joining BGCR DRC,
                      SCAR-DRC-UTMOST Insurance Ltd as a MAnager in charge of
                      Business Development and Chief Underwriting, Falcon
                      Insurance Services Ltd as Client Relationship Executive.
                    </p>
                    <p>
                      He holds a MBA in Risk Management-Insurance from Ecole
                      Supérieure d'Assurances de Paris-France.
                    </p>
                  </div>
                </div>
                {/* <!--Valens HABIMANA--> */}
                {/* <!--Hassan Lumumba--> */}
                <div className="board-member" style={{ display: "none" }}>
                  <div className="b-person">
                    <div className="person-profile">
                      <div className="img-profile">
                        <img
                          src={philipeRubega}
                          alt=""
                        />
                      </div>
                      <div className="user-names">
                        <p className="name">Hassan Lumumba</p>
                        <p className="job">Director</p>
                      </div>
                    </div>
                  </div>
                  <div className="b-details">
                    <p>
                      Col (Rtd){" "}
                      <span className="short-name">
                        Rucyemanagabo Hassan Lumumba
                      </span>{" "}
                      is an Ex-army Infantry Officer, Entrepreneur and the
                      Chairman / CEO Dash-s Technologies Inc. an IT company
                      based in Rwanda. Hassan served as Rwanda Defense Forces
                      (RDF) Director of Operation and Training.
                    </p>
                    <p>
                      After serving in the military for 23 years, Col Lumumba
                      took voluntary retirement and founded Dash-s Technologies
                      Inc. in 2006. He is a graduate of Defense Staff College
                      and Royal Melbourne Institute of Technology in Applied
                      Computer Science.
                    </p>
                    <p>
                      Today, he has translated the military traits of
                      discipline, tenacity, motivation, and leadership into
                      successful entrepreneurship. He is a well-regarded
                      business leader in the IT industry and his company was
                      recognized nationally as the best IT start-up 2009 and
                      internationally for Total Quality Management 2012. Hassan
                      is a founding member and a Board Member of Falcon Capital
                      Investments.
                    </p>
                  </div>
                </div>
                {/* <!--Hassan Lumumba--> */}
                {/* <!--Musinguzi Martin--> */}
                <div className="board-member" style={{ display: "none" }}>
                  <div className="b-person">
                    <div className="person-profile">
                      <div className="img-profile">
                        <img
                          src={philipeRubega}
                          alt="Musinguzi Martin"
                        />
                      </div>
                      <div className="user-names">
                        <p className="name">Musinguzi Martin</p>
                        <p className="job">Director</p>
                      </div>
                    </div>
                  </div>
                  <div className="b-details">
                    <p>
                      Musinguzi Martin is the founder of Musinguzi Law Group
                      (MLG) based in North Carolina USA. Martin holds an LLM
                      from Harvard Law School and a Bachelor of Laws Degree from
                      Butare University.
                    </p>
                    <p>
                      He has worked in the Immigration Department of the US
                      Federal Government and focused on working asylum and
                      deportation relief cases. Martin is licensed to practice
                      law in New York State, North Carolina and Rwanda. MLG's
                      main offices are in Uptown Charlotte, NC and recently
                      opened a satellite office in North Carolina's Research
                      Triangle Area. Martin is a member of Falcon Capital
                      Investments.
                    </p>
                  </div>
                </div>
                {/* <!--Musinguzi Martin--> */}
              </div>
              <div className="right">
                {/* <!--Insurance Market Stability--> */}
                <div className="profile-card card mb-4">
                  <img
                    src={marketChart}
                    className="card-img-top"
                    alt="Insurance Market Stability"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Insurance Market Stability</h5>
                    <div className="card-text">
                      <ul className="list-right-pointers">
                        <li>
                          The financial and management strength, attitude
                          towards claims and efficiency of the insurers is a
                          matter we take very seriously.
                        </li>
                        <li>
                          We conduct a review of all the insurance companies
                          annually and rate them, according to the Following
                          categories; Outstanding, A, B, C, D.
                        </li>
                        <li>
                          We ordinarily place our business with the insurance
                          companies in the outstanding, A, B & C categories.
                          Should a client prefer to have a placement in an
                          insurance company Rated otherwise we insist on a
                          letter of instruction.
                        </li>
                        <li>
                          This rating of insurance companies is not static and
                          we review it regularly on an on-going basis.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <!--Insurance Market Stability--> */}
                {/* <!--Why Falcon Insurance Services?--> */}
                <div className="profile-card card mb-4">
                  <img
                    src={marketChoice}
                    className="card-img-top"
                    alt=">Why Falcon Insurance Services"
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      Why Falcon Insurance Services?
                    </h5>
                    <div className="card-text">
                      <ul className="list-right-pointers">
                        <li>
                          We offer you portfolio advice and services that suit
                          your needs
                        </li>
                        <li>
                          In addition, we have the persuasiveness to request
                          greater discounts due to volume of Business and get
                          claims paid faster.
                        </li>
                        <li>
                          If you deal direct, you are obliged to accept only
                          what's on offer and simply have no legal recourse in
                          the event of miss-information.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <!--Why Falcon Insurance Services?--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}