/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import {Link, Outlet} from 'react-router-dom';

import {ReactComponent as FacebookLogo} from 'src/assets/images/facebook.svg';
import {ReactComponent as TwitterLogo} from 'src/assets/images/twitter.svg';
import {ReactComponent as LinkedinLogo} from 'src/assets/images/linkedin.svg';
import {ReactComponent as InstagramLogo} from 'src/assets/images/instagram.svg';

import callCenterSvgImg from 'src/assets/images/call-center-people.svg';
import emailUsSvgImg from 'src/assets/images/email_us.svg';
import buildingSvgImg from 'src/assets/images/building.svg';


import nyabugogoOffice from 'src/assets/images/nyabugogo_office_placeholder.jpg';
import musanzeOffice from 'src/assets/images/musanze_office_placeholder.jpg';

import needsBusiness from 'src/assets/images/need_business.jpg'
import logFisWhiteBk from 'src/assets/images/logo_fis_1_whie_bk.png';
import accidentCrash from 'src/assets/images/accident_crash.jpg';

import logoFisWebWhite from 'src/assets/images/logo_fis_1_whie_bk.png';
import { useState } from 'react';
import { generalFx } from 'src/utils/general_fxs';

export default function MainLayout(){
    const appYear = new Date().getFullYear();
    const [isMobileMenu, setIsMobileMenu] = useState<boolean>(false);
    const classVal = 'isMenuVisible';
    const parentId = generalFx.generateUUID();

    const onChangeMenu = () => {
        setIsMobileMenu(!isMobileMenu);
    }

    window.addEventListener('click', (e: MouseEvent) => {
        try {
            if(!document.getElementById(parentId)!.contains(e.target as Node)){
                setIsMobileMenu(false);
            }
            
        } catch (error) {
            
        }
    });
    const onRemoveMobileMenu = () => {
        setIsMobileMenu(false);
    }


    return (
        <>
            <header id={parentId}>
                <div className="header-banner">
                    <div className="container header-info">
                        <p className="area-info"></p>
                        <div className="area-btns">
                            <a className="btn ws-btn-small" href="tel:+250788301033" title="Place a call on this number">Call: (+250) 788-301-033 <i className="fas fa-phone"></i></a>&nbsp;
                            <a className="btn ws-btn-small" href="mailto:info@fis.rw" target="_blank" rel='noreferrer'>info@fis.rw <i className="fas fa-envelope"></i></a>
                        </div>
                    </div>
                </div>
                <div className="header-menu desktop">
                    <div className="container">
                        <nav className="main-nav">
                            <Link to="/">
                                <img src={logFisWhiteBk} alt="" className="img logo" />
                            </Link>
                            <ul className="list-none flex main-menu">
                                <li className="home">
                                    <Link to="/" className="link">
                                        <span>Home</span>
                                    </Link>
                                </li>
                                <li className="about">
                                    <a className="link link-data">About</a>
                                    <ul className="submenu aboutus list-none">
                                        <li><Link to="/about/company"><span>Company profile</span></Link></li>
                                        <li><Link to="/about/board"><span>Board of Directors</span></Link></li>
                                        <li><Link to="/about/staff"><span>Management Staff</span></Link></li>
                                    </ul>
                                </li>
                                <li className="products">
                                    <a className="link link-data">Products and Services</a>
                                    <div className="submenu products list-none">
                                        <div className="row">
                                            <div className="col-md-6 col-lg-3">
                                                <div className="image-holder-down">
                                                    <img src={accidentCrash}
                                                        alt="Car insurance" />
                                                    <Link to="/contactus" className="wise-btn primary">Contact Us</Link>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-3">
                                                <h4 className="prod-grp-title">
                                                    <span>Individual</span> Products
                                                </h4>
                                                <ul className="list-none product-list">
                                                    <li><Link to="/products/indivprods/health">Health Insurance</Link></li>
                                                    <li><Link to="/products/indivprods/motor">Motor Private</Link></li>
                                                    <li><Link to="/products/indivprods/domestic">Domestic Package</Link></li>
                                                    <li><Link to="/products/indivprods/persacc">Personal Accident</Link></li>
                                                    <li><Link to="/products/indivprods/medinsur">Medical Insurance</Link></li>
                                                    <li><Link to="/products/indivprods/profindemn">Professional Indemnity</Link></li>
                                                    <li><Link to="/products/indivprods/goodstransit">Goods in Transit</Link></li>
                                                    <li><Link to="/products/indivprods/bonds">Bonds</Link></li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6 col-lg-3">
                                                <h4 className="prod-grp-title">
                                                    <span>Corporate</span> Products
                                                </h4>
                                                <ul className="list-none product-list">
                                                    <li><Link to="/products/corpprods/fire">Fire</Link></li>
                                                    <li><Link to="/products/corpprods/burglary">Bulgary</Link></li>
                                                    <li><Link to="/products/corpprods/allrisks">All risks</Link></li>
                                                    <li><Link to="/products/corpprods/money">Money</Link></li>
                                                    <li><Link to="/products/corpprods/fidelityguar">Fidelity Guarantee</Link></li>
                                                    <li><Link to="/products/corpprods/goodstransit">Goods in Transit</Link></li>
                                                    <li><Link to="/products/corpprods/commercmotor">Commercial Motor</Link></li>
                                                    <li><Link to="/products/corpprods/groupersonacc">Group Personal Accident</Link></li>
                                                    <li><Link to="/products/corpprods/marine">Marine</Link></li>
                                                    <li><Link to="/products/corpprods/contractors">Contractors All Risks</Link></li>
                                                    <li><Link to="/products/corpprods/injurybenefit">Work Injury Benefit Act</Link></li>
                                                    <li><Link to="/products/corpprods/glass">Glass</Link></li>
                                                    <li><Link to="/products/corpprods/publicliab">Public Liability</Link></li>
                                                    <li><Link to="/products/corpprods/professindemn">Professional Indemnity</Link></li>
                                                    <li><Link to="/products/corpprods/electrequip">Electronic Equipment</Link></li>
                                                    <li><Link to="/products/corpprods/conseqloss">Consequential Loss</Link></li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6 col-lg-3">
                                                <h4 className="prod-grp-title">
                                                    <span>SME</span> Products
                                                </h4>

                                                <div className="card mb-4">
                                                    <img src={needsBusiness}
                                                        className="card-img-top" alt="Need business"/>
                                                    <div className="card-body">
                                                        <h5 className="card-title">Do you own a business?</h5>
                                                        <p className="card-text mb-2">
                                                            In case your business needs an insurance plan, kindly contact us
                                                            for further info.
                                                        </p>
                                                        <Link to="/products/smeprods" className="btn btn-primary">Learn more</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="contact"><Link to="/contactus" className="link">Contacts</Link></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className={"header-menu mobile " + (isMobileMenu ? classVal: '')} id="menuWrapper">
                    <div className="mobile-bar" id="mobileBar">
                        <Link to="/">
                            <img src={logoFisWebWhite} alt="Fis Logo" />
                        </Link>
                        <span className="mobile-menu-activator" id="mobileButton"
                        onClick={onChangeMenu}>
                            <i className="fas fa-bars btn-bar-menu"></i>
                            <i className="fas fa-times btn-bar-cancel"></i>
                        </span>
                    </div>
                    <div className="mobile-menu" id="mobileMenu">
                        <div className="accordion" id="accordionMenuFis">
                            <div className="card">
                                <div className="card-header" id="headingHome">
                                    <h2 className="mb-0">
                                        <Link className="btn btn-link btn-block text-left" to="/" onClick={onRemoveMobileMenu}>
                                            Home
                                        </Link>
                                    </h2>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingAboutUs">
                                    <h2 className="mb-0">
                                        <button className="btn btn-link btn-block text-left collapsed"
                                                type="button"
                                                data-toggle="collapse"
                                                data-target="#collapseAboutUs"
                                                aria-expanded="false"
                                                aria-controls="collapseAboutUs">
                                            About Us
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseAboutUs"
                                    className="collapse"
                                    aria-labelledby="headingAboutUs"
                                    data-parent="#accordionMenuFis">
                                    <div className="card-body">
                                        <ul className="list-group">
                                            <li className="list-group-item">
                                                <Link to="/about/company" onClick={onRemoveMobileMenu}>Company Profile</Link>
                                            </li>
                                            <li className="list-group-item">
                                                <Link to="/about/board" onClick={onRemoveMobileMenu}>Board of Directors</Link>
                                            </li>
                                            <li className="list-group-item">
                                                <Link to="/about/staff" onClick={onRemoveMobileMenu}>Management Staff</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingThree">
                                    <h2 className="mb-0">
                                        <button className="btn btn-link btn-block text-left collapsed"
                                                type="button"
                                                data-toggle="collapse"
                                                data-target="#collapseThree"
                                                aria-expanded="false"
                                                aria-controls="collapseThree">
                                            Products and Services
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseThree"
                                    className="collapse"
                                    aria-labelledby="headingThree"
                                    data-parent="#accordionMenuFis">
                                    <div className="card-body">
                                        <ul className="list-group">
                                            <li className="list-group-item">
                                                <Link to="/products/indivprods" onClick={onRemoveMobileMenu}><span>Individual</span> Products</Link>
                                            </li>
                                            <li className="list-group-item">
                                                <Link to="/products/corpprods" onClick={onRemoveMobileMenu}><span>Corporate</span> Products</Link>
                                            </li>
                                            <li className="list-group-item">
                                                <Link to="/products/smeprods" onClick={onRemoveMobileMenu}><span>SME</span> Products</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingContactUs">
                                    <h2 className="mb-0">
                                        <Link className="btn btn-link btn-block text-left" to="/contactus" onClick={onRemoveMobileMenu}>
                                            Contact Us
                                        </Link>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <Outlet/>
            <footer>
                <div className="container main-footer">
                    <div className="row upper-footer">
                        <div className="col-md-6 col-lg-3 fis-main">
                            <div className="fwrapper">
                                <Link to="/">
                                    <img src={logoFisWebWhite} alt=''/>
                                </Link>
                                <p>Independent provider of financial and risk services</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 fis-call">
                            <div className="fwrapper">
                                <div className="flex-1">
                                    <h4>Call Us</h4>
                                    <div className="content">
                                        <p style={{display: 'none'}}>
                                            <span>Administration:</span>&nbsp;
                                            <a href="tel:+250788509554" title="Place a call on this number">Call: (+250) 788-509-554</a>
                                        </p>
                                        <p>
                                            <span>Management:</span>&nbsp;
                                            <a href="tel:+250788301033" title="Place a call on this number">Call: (+250) 788-301-033</a>
                                        </p>
                                    </div>
                                </div>
                                <div className="small-icon">
                                    <img src={callCenterSvgImg} alt="Call center" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 fis-email">
                            <div className="fwrapper">
                                <div className="flex-1">
                                    <h4>Email Us</h4>
                                    <div className="content">
                                        <p><a href="mailto:info@fis.rw">info@fis.rw</a></p>
                                    </div>
                                </div>
                                <div className="small-icon">
                                    <img src={emailUsSvgImg} alt="Email us" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 fis-office">
                            <div className="fwrapper">
                                <div className="flex-1">
                                    <h4>Office Address</h4>
                                    <div className="content">
                                        <p><i className="fas fa-building"></i> Centenary House Building, M1-Left Wing</p>
                                        <p><i className="fas fa-road"></i> 8 KN 4 Ave, CH Mezanine</p>
                                        <p><i className="fas fa-street-view"></i> Nyarugenge</p>
                                        <p><i className="fas fa-inbox"></i> P.O.Box 2523 Kigali, Rwanda</p>
                                    </div>
                                </div>
                                <div className="small-icon">
                                    <img src={buildingSvgImg} alt="Office address" />
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <div className="fis-offices">
                        <h2 className="off-title">Offices</h2>
                        <h4>Please visit our nearest office for further information on our products</h4>
                        <div className="row">
                            <div className="col-md-6 office-cover">
                                <div className="img">
                                    <img src={nyabugogoOffice} className="w-100"
                                        alt="Nyabugogo" />
                                </div>
                                <div className="fis-office-details">
                                    <h4>Nyabugogo</h4>
                                    <div className="content">
                                        <p><i className="fas fa-building"></i> Nyabugogo Liason Office</p>
                                        <p><i className="fas fa-road"></i> White Building</p>
                                        <p><i className="fas fa-street-view"></i> Next Chez Manu</p>
                                        <p><i className="fas fa-envelope"></i> <a href="emailto:nyabugogo@fis.rw">nyabugogo@fis.rw</a></p>
                                        <p><i className="fas fa-phone"></i> <a href="tel:+250788358403">(+250) 788-358-403</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 office-cover">
                                <div className="img">
                                    <img src={musanzeOffice} className="w-100"
                                        alt="Musanze" />
                                </div>
                                <div className="fis-office-details">
                                    <h4>Musanze</h4>
                                    <div className="content">
                                        <p><i className="fas fa-building"></i> Musanze Immeuble Kanyandekwe</p>
                                        <p><i className="fas fa-road"></i> Gd Floor </p>
                                        <p><i className="fas fa-envelope"></i> <a href="emailto:musanze@fis.rw">musanze@fis.rw</a></p>
                                        <p><i className="fas fa-phone"></i> <a href="tel:+250788264928">(+250) 788-264-929</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="separator" />
                    <div className="container lower-footer">
                        <div className="social-area">
                            <a href="https://www.facebook.com/falconinsurance2020/" className="social facebook" target="_blank" rel='noreferrer'>
                                <FacebookLogo fill='#fff' />
                            </a>
                            <a href="https://twitter.com/falconinsuranc" className="social twitter" target="_blank" rel='noreferrer'>
                                <TwitterLogo fill='#fff' />
                            </a>
                            <a href="https://www.linkedin.com/in/falcon-insurance-services-17711420b/"
                                className="social linkedin" target="_blank" rel='noreferrer'>
                                <LinkedinLogo fill='#fff' />
                            </a>
                            <a href="https://www.instagram.com/falconinsuranceservices/" className="social youtube" target="_blank" rel='noreferrer'>
                                <InstagramLogo fill='#fff' />
                            </a>
                        </div>
                        <div className="browsing">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about/company">Company Profile</Link></li>
                                <li><a href="https://250insurances.fis.rw" target="_blank" rel='noreferrer'>250Insurances Portal</a></li>
                                <li><Link to="/contactus">Contact Us</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="fis-objective">
                        Falcon Insurance Services (FIS) &copy; {appYear}
                    </div>
                    <div className="fis-designer">
                        Designed by <a target="_blank" href="http://wiseobjects.com" rel='noreferrer'>WiseObjects Ltd</a>&nbsp;
                        • <Link to="/legal/cookies">Cookies Policy</Link>&nbsp;
                        • <Link to="/legal/privacy">Privacy Policy</Link>&nbsp;
                        • <Link to="/legal/terms">Terms</Link>&nbsp;
                    </div>
                </div>                
            </footer>
        </>
    );
}