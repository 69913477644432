import { useEffect } from "react";

import OurServiceComp from "src/components/OurServiceComp";
import QuoteFormComp from "src/components/QuoteFormComp";


import healthInsur from 'src/assets/images/bonds_ins.svg';


export default function BondsProd(){
    document.title = 'Bonds - FIS Ltd';

    useEffect(() => {    
        window.scrollTo(0, 0);
    }, []);

    return (
      <section>
        <div className="content-header">
          <div className="container">
            <div className="header-inner">
              <h3>Bonds</h3>
              <img src={healthInsur} alt="" />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container">
            <div className="boddy-inner">
              <div className="left">
                <p>
                  This are guarantees that may from time to time be required by
                  other parties to be executed by an insurance company to
                  certify that the insured will observe or maintain a certain
                  state of affairs or will perform a certain task to the
                  required standard or as per an agreed contract.eg Customs
                  Bonds, Immigrations/Security Bonds, Performance/Contractor's
                  Bonds and Tender/bid bonds.
                </p>
              </div>
              <div className="right">
                {/* <!--Request quote--> */}
                <QuoteFormComp productName={"Bonds Insurance"} />
                {/* <!--Request quote--> */}
                {/* <!--Our services--> */}
                <OurServiceComp />
                {/* <!--Our services--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}