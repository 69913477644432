import { generalFx } from "src/utils/general_fxs";


type InputComponentProps = {
    componentId: string;
    placeholder: string;
    value: string;
    type: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onChangeArea?: React.ChangeEventHandler<HTMLTextAreaElement>;
    disabled?: boolean;
    hasError?: boolean;
    maxLength?: number;
    isNumericFld?: boolean;
    isTextArea?: boolean;
    rows?: number;
}


export default function FormgroupInput({
    componentId,
    placeholder,
    onChange,
    onChangeArea,
    value,
    type,
    disabled,
    hasError,
    maxLength,
    isNumericFld,
    isTextArea,
    rows,
  }:InputComponentProps) {
    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const bNumeric = isNumericFld ?? false;
        if(bNumeric){
           generalFx.strictlyNbr(e)
        }
      }


  return (
    <div className="form-group">
        {!isTextArea ?
          <input
            type={type || "text"}
            value={value}
            name={componentId}
            id={componentId}
            disabled={disabled || false}
            placeholder={placeholder}
            maxLength={maxLength || 25}
            className="form-control selectallfocus"
            autoComplete="false"
            onChange={onChange}
            onKeyPress={handleKeyPress}
          />:
          <textarea
            name={componentId}
            id={componentId}
            placeholder={placeholder}
            className="form-control selectallfocus"
            onChange={onChangeArea}
            value={value}
            rows={rows || 4}
            maxLength={maxLength || 25}
            autoComplete="off"></textarea>
          }
          <span className={`form-error prod ${(hasError ?? false) ? '': 'd-none'}`}>
            This field is required
          </span>
        </div>
  )
}
