import { useEffect } from "react";

import OurServiceComp from "src/components/OurServiceComp";
import QuoteFormComp from "src/components/QuoteFormComp";


import healthInsur from 'src/assets/images/health_insur.svg';

export default function Health(){
    document.title = 'Health insurance - FIS Ltd';

    useEffect(() => {    
        window.scrollTo(0, 0);
    }, []);

    return (
      <section>
        <div className="content-header">
          <div className="container">
            <div className="header-inner">
              <h3>Health Insurance</h3>
              <img src={healthInsur} alt="" />
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container">
            <div className="boddy-inner">
              <div className="left">
                <p>
                  Health insurance is a type of insurance that covers the whole
                  or a part of the risk of a person incurring medical expenses.
                  As with other types of insurance is risk among many
                  individuals. By estimating the overall risk of health risk and
                  health system expenses over the risk pool, an insurer can
                  develop a routine finance structure, such as a monthly premium
                  or payroll tax, to provide the money to pay for the health
                  care benefits specified in the insurance agreement. The
                  benefit is administered by a central organization, such as a
                  government agency, private business, or not-for-profit entity.
                </p>
                <p>
                  According to the Health Insurance Association of America,
                  health insurance is defined as "coverage that provides for the
                  payments of benefits as a result of sickness or injury. It
                  includes insurance for losses from accident, medical expense,
                  disability, or accidental death and dismemberment".
                </p>
              </div>
              <div className="right">
                {/* <!--Request quote--> */}
                <QuoteFormComp productName={"Health Isnurance"} />
                {/* <!--Request quote--> */}
                {/* <!--Our services--> */}
                <OurServiceComp />
                {/* <!--Our services--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}