import Swal from "sweetalert2";

const numberComma = function (event:any, thisValue: string) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (thisValue.indexOf('.') !== -1) && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105))
        return false;
    return true;
},
numberOnlyV2 = function (event:any) {
    return event.charCode >= 48 && event.charCode <= 57
},
generateUUID = function () {
    let d = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
    });
    return uuid;
},
getRandomInt = function (min:number, max:number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
},
getRandomName = function (min:number, max: number) {
    let nbr = getRandomInt(min, max);
    let ddt = new Date();

    let dtName = ddt.getDay().toString() + "_" +
                    ddt.getDate().toString() + "_" +
                    ddt.getMonth().toString() + "_" +
                    ddt.getFullYear().toString() + "_" +
                    ddt.getHours().toString() + "_" +
                    ddt.getSeconds().toString() + "_" +
                    ddt.getMinutes().toString();

    return dtName + nbr.toString();
},
getRandomNew = function () {
    let nbr = getRandomInt(0, 9);
    let ddt = new Date();

    let dtName = ddt.getDay().toString() +
                    ddt.getDate().toString() +
                    ddt.getMonth().toString() +
                    ddt.getFullYear().toString() +
                    ddt.getHours().toString() +
                    ddt.getSeconds().toString() +
                    ddt.getMinutes().toString();

    return dtName + nbr.toString();
},
verifCheckPhoneNumberFormat = (phoneNbr:string) => {
    if (phoneNbr === null) { return false; }
    if (phoneNbr.length === 0) { return false; }

    let arrayPhones = phoneNbr.split(';');

    for (let i = 0; i < arrayPhones.length; i++) {
        let iPh = arrayPhones[i];
        if (iPh.length !== 12) {
            return false;
        }
    }

    return true;
},
validPhoneNbr = (phnNbr:string) => {
    return ((phnNbr !== null && phnNbr.length) && (phnNbr.length === 12));
},
repeatString = function (nTimes: number, sString:string) {
    return new Array(nTimes + 1).join(sString);
},
onlyAlphabets = (evt: any) => {
    let charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105))
        return true;
    return false;
},
dateDiffDays = (first:number, second:number) => {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
},
dateToUtcStr= (dateRef:Date) => {
    return new Date(dateRef).toISOString();
    
},
randomCharsFormChars = (len:number) => {
    let result = [];
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const charLength = chars.length;
    for (let idx = 0; idx < len; idx++) {
        result.push(chars.charAt(Math.floor(Math.random() * charLength)));
    }
    return result.join('');
},
randomCharsFormInts = (len:number) => {
    let result = [];
    const chars = '0123456789';
    const charLength = chars.length;
    for (let idx = 0; idx < len; idx++) {
        result.push(chars.charAt(Math.floor(Math.random() * charLength)));
    }
    return result.join('');
},
randomCharsFormAlphaNum = (len:number) => {
    let result = [];
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charLength = chars.length;
    for (let idx = 0; idx < len; idx++) {
        result.push(chars.charAt(Math.floor(Math.random() * charLength)));
    }
    return result.join('');
},

isEmailValid = (email: string):boolean => {
    const reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    return reg.test(email);
},

strictlyNbr = (evt: any) => {
    const event = (evt) ? evt: window.event;
    const charCode = (event.which) ? event.which: event.keyCode;

    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
    } else {
        return true;
    }
},

showAlert = (msgTitle: string, msgBody: string, cancelButtonText: string = 'Cancel') => {
    return Swal.fire({
        title: msgTitle,
        text: msgBody,
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText,
        allowEscapeKey: false,
        allowOutsideClick: false,
    });
};

export const generalFx = {
    numberComma,
    numberOnlyV2,
    generateUUID,
    getRandomInt,
    getRandomName,
    getRandomNew,
    verifCheckPhoneNumberFormat,
    validPhoneNbr,
    repeatString,
    onlyAlphabets,
    dateDiffDays,
    dateToUtcStr,
    randomCharsFormChars,
    randomCharsFormInts,
    randomCharsFormAlphaNum,
    isEmailValid,
    strictlyNbr,
    showAlert,
}